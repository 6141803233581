import { YuLifeLocale } from "hooks";
import { MobileFriendlyP1, StyledLegend } from "./styles";

type Data = Record<YuLifeLocale, { paragraph: JSX.Element; languageOptions: LanguageOptions }>;

interface LanguageOptions {
  uk: CountryOption;
  us: CountryOption;
  za: CountryOption;
  jp?: CountryOption;
}

interface CountryOption {
  country: string;
  flag: string;
  rewardLocation: string;
}

const data: Data = {
  uk: {
    paragraph: (
      <>
        <StyledLegend>Select your region</StyledLegend>
        <MobileFriendlyP1 pt="0.25rem" pb="1rem" lineHeight="1.75rem">
          You’re on the <strong>United Kingdom</strong> version of the site. Did you want to change
          your region?
        </MobileFriendlyP1>
      </>
    ),
    languageOptions: {
      uk: { country: "United Kingdom", flag: "GB-UKM", rewardLocation: "GB" },
      us: { country: "United States", flag: "US", rewardLocation: "US" },
      za: { country: "South Africa", flag: "ZA", rewardLocation: "ZA" },
      // commenting out until Japan launch
      // jp: { country: "Japan", flag: "JP", rewardLocation: "JP" },
    },
  },
  us: {
    paragraph: (
      <>
        <StyledLegend>Select your region</StyledLegend>
        <MobileFriendlyP1 pt="0.25rem" pb="1rem" lineHeight="1.75rem">
          You’re on the <strong>United States</strong> version of the site. Did you want to change
          your region?
        </MobileFriendlyP1>
      </>
    ),
    languageOptions: {
      uk: { country: "United Kingdom", flag: "GB-UKM", rewardLocation: "GB" },
      us: { country: "United States", flag: "US", rewardLocation: "US" },
      za: { country: "South Africa", flag: "ZA", rewardLocation: "ZA" },
      // commenting out until Japan launch
      // jp: { country: "Japan", flag: "JP", rewardLocation: "JP" },
    },
  },
  za: {
    paragraph: (
      <>
        <StyledLegend>Select your region</StyledLegend>
        <MobileFriendlyP1 pt="0.25rem" pb="1rem" lineHeight="1.75rem">
          You’re on the <strong>South Africa</strong> version of the site. Did you want to change
          your region?
        </MobileFriendlyP1>
      </>
    ),
    languageOptions: {
      uk: { country: "United Kingdom", flag: "GB-UKM", rewardLocation: "GB" },
      us: { country: "United States", flag: "US", rewardLocation: "US" },
      za: { country: "South Africa", flag: "ZA", rewardLocation: "ZA" },
      // commenting out until Japan launch
      // jp: { country: "Japan", flag: "JP", rewardLocation: "JP" },
    },
  },
  jp: {
    paragraph: (
      <>
        <StyledLegend>ご利用の国/地域を選択してください。</StyledLegend>
        <MobileFriendlyP1 pt="0.25rem" pb="1rem" lineHeight="1.75rem">
          現在、 <strong>日本版</strong>サイトをご覧いただいています。地域を変更しますか？
        </MobileFriendlyP1>
      </>
    ),
    languageOptions: {
      uk: { country: "イギリス", flag: "GB-UKM", rewardLocation: "GB" },
      us: { country: "アメリカ", flag: "US", rewardLocation: "US" },
      za: { country: "南アフリカ", flag: "ZA", rewardLocation: "ZA" },
      jp: { country: "日本", flag: "JP", rewardLocation: "JP" },
    },
  },
};

export default data;
