import NextLink, { LinkProps } from "next/link";
import { useSearchParams } from "next/navigation";
import { PropsWithChildren } from "react";

export const Link = ({ href, children, ...rest }: PropsWithChildren<LinkProps>) => {
  const searchParams = useSearchParams();
  const queryParams = searchParams?.toString() || "";
  const params = new URLSearchParams(queryParams);
  params.delete("slug");
  const filteredParams = params.toString();

  if (filteredParams) {
    return (
      <NextLink href={`${href}?${filteredParams}`} {...rest}>
        {children}
      </NextLink>
    );
  }

  return (
    <NextLink href={href} {...rest}>
      {children}
    </NextLink>
  );
};
