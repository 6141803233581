import { color } from "@styles";
import { NavbarType } from "components/organisms/Navigation/components/Navbar/Navbar.types";
import { FC } from "react";

export const MenuArrow: FC<
  React.PropsWithChildren<{ colour?: string; navbarType: NavbarType; isScrolled: boolean }>
> = ({ colour, navbarType, isScrolled }) => (
  <svg width="20" height="16" viewBox="-4 -7 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.33331 2.66669L3.99998 5.33335L6.66665 2.66669"
      stroke={colour}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
