import { FC } from "react";
import styled from "styled-components";

interface Props {
  color?: string;
  isMinimized?: boolean;
}

export const HamburgerMenu: FC<React.PropsWithChildren<Props>> = ({
  color = "#640038",
  isMinimized,
}) => (
  <SVG
    isMinimized={isMinimized}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.6665 10H33.3332"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.6665 20H33.3332"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.6665 30H33.3332"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SVG>
);

const SVG = styled.svg<{ isMinimized?: boolean }>`
  @media only screen and (max-width: 30rem) {
    height: ${(p) => (p.isMinimized ? "1.5rem" : "2.5rem")};
  }
`;
