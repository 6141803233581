import { forwardRef, ReactNode } from "react";
import styled from "styled-components";
import { tablet, mobile } from "./Breakpoints";

interface Props {
  children?: ReactNode;
  pt?: string;
  pb?: string;
  pr?: string;
  background?: string;
  maxWidth?: string;
  className?: string;
  pl?: string;
  margin?: string;
  mobPt?: string;
}

const _StandardSection = (
  {
    pt,
    pb,
    pl,
    pr,
    background = "transparent",
    maxWidth = "80rem",
    children,
    className,
    margin,
    mobPt,
  },
  ref,
) => (
  <Section
    mobPt={mobPt}
    className={className}
    ref={ref}
    background={background}
    pl={pl}
    pr={pr}
    pt={pt}
    pb={pb}
  >
    <Centered maxWidth={maxWidth} margin={margin}>
      {children}
    </Centered>
  </Section>
);

export const StandardSection = forwardRef<HTMLDivElement, Props>(_StandardSection);

export const StandardGap = styled.div`
  width: 100%;
  height: 7.5rem;

  ${tablet} {
    height: 5rem;
  }
`;

const Section = styled.div<Props>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  background: ${(p) => p.background};
  padding-top: ${(p) => p.pt || "7.5rem"};
  padding-bottom: ${(p) => p.pb || "7.5rem"};
  padding-left: ${(p) => p.pl || "5%"};
  padding-right: ${(p) => p.pr || "5%"};
  overflow: visible;

  @media only screen and (max-width: 30rem) {
    padding-left: 5%;
    padding-right: 5%;
  }

  ${tablet} {
    padding-top: ${(p) => p.pt || "5rem"};
    padding-bottom: ${(p) => p.pb || "5rem"};
    padding-left: 5%;
    padding-right: 5%;
  }

  ${mobile} {
    padding-top: ${(p) => p.mobPt || "1.5rem"};
    padding-bottom: ${(p) => p.pb || "1.5rem"};
  }
`;

const Centered = styled.div<Props>`
  width: 100%;
  height: 100%;
  max-width: ${(p) => p.maxWidth};
  margin: ${(p) => p.margin || "0 auto"};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
