import { color } from "@styles";
import { mobile } from "components/templates/Breakpoints";
import styled from "styled-components";
import { StandardPadding } from "../../../../templates/StandardPadding";
import { NavbarType } from "./Navbar.types";

const NAV_TRANSITION = "all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99)";

export const fadeIn = {
  transitionDuration: 160,
  defaultTransitionStyle: {
    transition: `opacity 160ms ease-in-out`,
    opacity: 0,
  },
  transitionStyles: {
    entering: { opacity: 1, pointerEvents: "auto" },
    entered: { opacity: 1, pointerEvents: "auto" },
    exiting: { opacity: 0, pointerEvents: "none" },
    exited: { opacity: 0, pointerEvents: "none" },
  },
};

export const Nav = styled.nav<{
  isScrolled?: boolean;
  openMobileNav?: boolean;
  backgroundColor?: string;
}>`
  transition:
    background 0.05s,
    border-bottom 0.05s;
  ${(p) => (p.isScrolled ? `border-bottom: 1px solid ${color.B230}` : "")};
  background-color: ${(p) => (p.isScrolled ? "white" : p.backgroundColor || "white")};
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9;
  margin-bottom: -2.5rem;
`;

export const Padding = styled(StandardPadding)`
  display: flex;
  justify-content: space-between;
`;

export const LogoContainer = styled.div<{ isMinimized?: boolean }>`
  height: auto;
  transition: ${NAV_TRANSITION};
  padding-top: ${(p) => (p.isMinimized ? "0.9rem" : "2rem")};
  padding-bottom: ${(p) => (p.isMinimized ? "0.5rem" : "2rem")};
  flex-grow: 0;
  flex-basis: 8.625rem;
  cursor: pointer;
  z-index: 9;

  ${mobile} {
    padding: ${(p) => (p.isMinimized ? "0.65rem 0 0.25rem 0" : "1rem 0 0.75rem 0")};
  }
`;

export const NavButton = styled.button<{ buttonType: NavbarType; isMinimized?: boolean }>`
  margin: 0.5rem;
  border-radius: 0.5rem;
  font-weight: bold;
  font-size: 1rem;
  text-align: center;
  letter-spacing: 0.075rem;
  cursor: pointer;
  transition: ${NAV_TRANSITION};
  color: #ffffff;
  align-items: center;
  justify-content: center;
  padding: 0.15rem 1.5rem 0 1.5rem;
  height: ${(p) => (p.isMinimized ? "2.5rem" : "3.5rem")};
  width: 11.5rem;

  background-color: ${(p) =>
    p.buttonType === NavbarType.primaryInverted ? "transparent" : color.P100};
  border: ${(p) =>
    p.buttonType === NavbarType.primaryInverted ? `2px solid ${color.white}` : "none"};
  box-sizing: border-box;

  &:hover {
    background-color: ${(p) =>
      p.buttonType === NavbarType.primaryInverted ? "#ffffff55" : "#E30D76bb"};
    border: 2px solid
      ${(p) => (p.buttonType === NavbarType.primaryInverted ? `2px solid ${color.white}` : "none")};
  }

  &:focus {
    background-color: ${(p) =>
      p.buttonType === NavbarType.primaryInverted ? "#ffffff55" : "#E30D76bb"};
    border: 2px solid
      ${(p) => (p.buttonType === NavbarType.primaryInverted ? `2px solid ${color.white}` : "none")};
  }
`;

export const MenuWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
`;

export const UL = styled.ul`
  grid-column: 1;
  grid-row: 1;
  list-style: none;
  display: flex;
  justify-content: right;
  align-items: center;
`;

const LI = styled.li<{ color?: string }>`
  position: relative;
  font-weight: bold;
  letter-spacing: 0.075rem;
  color: ${(p) => p.color || color.white};
  display: inline-block;
  padding: 2rem 1.25rem;
  cursor: pointer;

  @media only screen and (max-width: 83rem) {
    padding: 0 0.5rem;
  }

  ${mobile} {
    padding: 0;
  }
`;

export const NarrowLI = styled(LI)<{ color?: string }>`
  display: none;

  @media only screen and (max-width: 83rem) {
    display: inline-block;
    cursor: pointer;
  }
`;

export const WideLI = styled(LI)<{ color?: string }>`
  display: inline-block;

  @media only screen and (max-width: 83rem) {
    display: none;
  }
`;

export const NavLI = styled.div`
  position: relative;
  display: inline-block;
  grid-column: 2;
  grid-row: 1;
  padding: 0;
  padding-left: 1.5rem;
  display: inline-block;

  @media only screen and (max-width: 83rem) {
    display: none;
  }

  ${mobile} {
    padding: 0;
  }
`;

export const Anchor = styled.a<{ color?: string }>`
  text-decoration: inherit;
  color: ${(p) => p.color};

  &:hover {
    color: ${(p) => p.color};
    text-decoration: underline;
  }
`;
