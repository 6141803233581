import { color as YU_COLOURS } from "@styles";
import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  top: 0.15rem;
  right: -0.2rem;
  width: 3.125rem;
  height: 3.125rem;
`;

export const Line = styled.div`
  z-index: 9;
  height: 0.125rem;
  margin: 0.0625rem;
  width: 0.9375rem;
  background: ${YU_COLOURS.white};
  transition: 0.4s ease;
`;

export const LeftLine = styled(Line)<{ isOpen?: boolean }>`
  display: block;
  position: absolute;
  transform: rotate(${(p) => (p.isOpen ? 135 : 45)}deg);
  left: 25%;
  bottom: 35%;
`;

export const RightLine = styled(Line)<{ isOpen?: boolean }>`
  display: block;
  position: absolute;
  transform: rotate(${(p) => (p.isOpen ? -135 : -45)}deg);
  left: 45%;
  bottom: 35%;
`;
