import { FlagIcon } from "components/atoms/FlagIcon";
import { MegaMenuSmallBlock } from "components/molecules/MegaMenuSmallBlock";
import { useHandleLocaleSwitch } from "@hooks";
import { FC } from "react";
import styled from "styled-components";
import { Container, ResizableContainer } from "./styles";
import { shrinkNav } from "components/templates/Breakpoints";

export const MegaMenuLocale: FC<{ hideLinks?: boolean }> = ({ hideLinks = false }) => {
  const handleLocaleSwitch = useHandleLocaleSwitch();

  return (
    <ResizableContainer>
      <StyledContainer $hideLinks={hideLinks}>
        <Column>
          <MegaMenuSmallBlock title="UK" width="8rem" onClick={() => handleLocaleSwitch("uk")}>
            <Margin>
              <FlagIcon src="/static/icons/flags/GB-UKM.svg" locale="United Kingdom" />
            </Margin>
          </MegaMenuSmallBlock>

          <MegaMenuSmallBlock title="US" width="8rem" onClick={() => handleLocaleSwitch("us")}>
            <Margin>
              <FlagIcon src="/static/icons/flags/US.svg" locale="United States" />
            </Margin>
          </MegaMenuSmallBlock>

          <MegaMenuSmallBlock title="SA" width="8rem" onClick={() => handleLocaleSwitch("za")}>
            <Margin>
              <FlagIcon src="/static/icons/flags/ZA.svg" locale="South Africa" />
            </Margin>
          </MegaMenuSmallBlock>

          {process.env.JAPAN_SITE_LIVE === "true" &&
          <MegaMenuSmallBlock title="JP" width="8rem" onClick={() => handleLocaleSwitch("jp")}>
            <Margin>
              <FlagIcon src="/static/icons/flags/JP.svg" locale="Japan" />
            </Margin>
          </MegaMenuSmallBlock>
          }
        </Column>
      </StyledContainer>
    </ResizableContainer>
  );
};

const Margin = styled.div`
  margin: 0.2rem 0 0 0.8rem;
`;

const StyledContainer = styled(Container)<{ $hideLinks: boolean }>`
  width: 10rem;
  flex-direction: column;
  position: absolute;
  right: ${(p) => (p.$hideLinks ? "3rem" : "18.5rem")};

  ${shrinkNav} {
    right: 0rem;
  }
`;

const Column = styled.div`
  padding: 1rem;
`;
