import { useClientOnly } from "hooks";

/**
 * For a discussion of why this component is necessary, see:
 * https://www.joshwcomeau.com/react/the-perils-of-rehydration/
 *
 * In this project, <ClientOnly> is mostly used to wrap around any components
 * that contain Apollo hooks such as useQuery or useMutation
 */
export const ClientOnly = ({ children, ...delegated }) => {
  const { hasMounted } = useClientOnly();

  if (!hasMounted) {
    return null;
  }

  return <div {...delegated}>{children}</div>;
};
