import styled, { css } from "styled-components";
import { CSS } from "styled-components/dist/types";
import { color, fonts } from "../../styles";
import { forwardRef } from "react";

interface BetterButtonProps {
  variant?: "primary" | "secondary" | "inverted" | "purple" | "menu" | "purpleInverted";
  text: string;
  width?: string;
  isMinimised?: boolean;
  disabled?: boolean;
  minimisedWidth?: string;
  onClick?: () => void;
}

export const BetterButton = forwardRef(
  (
    {
      text,
      variant = "primary",
      width = "fit-content",
      isMinimised = false,
      disabled = false,
      minimisedWidth,
      ...props
    }: BetterButtonProps,
    _ref,
  ) => {
    return (
      <Button
        variant={variant}
        disabled={disabled}
        $width={width}
        isMinimised={isMinimised}
        $minimisedWidth={minimisedWidth}
        {...props}
      >
        {text}
        {variant === "purpleInverted" && (
          <svg xmlns="http://www.w3.org/2000/svg" width="27" height="15" fill="none">
            <path d="m2 1.3 11.5 11.4L25 1.3" stroke="#956AFF" strokeWidth="3" />
          </svg>
        )}
      </Button>
    );
  },
);

BetterButton.displayName = "BetterButton";

const primary = css`
  background: ${color.P100};
  border: 2px solid ${color.P100};
  color: ${color.white};

  &:hover {
    box-shadow: 0.5rem 0.5rem 0rem ${color.P20};
  }
`;

const secondary = css`
  background: ${color.P10};
  border: 2px solid ${color.P100};
  color: ${color.P100};

  &:hover {
    box-shadow: 0.5rem 0.5rem 0rem ${color.P20};
  }
`;

const inverted = css`
  background: ${color.white};
  border: 2px solid ${color.white};
  color: ${color.S1100};

  &:hover {
    background: ${color.S1100};
    color: ${color.white};
    box-shadow: 0.5rem 0.5rem 0rem ${color.S120};
  }
`;

const purple = css`
  background: ${color.S3100};
  color: ${color.white};

  &:hover {
    background: ${color.S3100};
    color: ${color.white};
    box-shadow: 0.5rem 0.5rem 0rem ${color.S320};
  }
`;

const purpleInverted = css`
  background: ${color.S330};
  color: ${color.S3100};
  border: 2px solid ${color.S3100};

  &:hover {
    background: ${color.S3100};
    color: ${color.white};
    box-shadow: 0.5rem 0.5rem 0rem ${color.S320};
    * {
      stroke: ${color.white};
    }
  }
`;

const menu = css`
  background: ${color.P100};
  color: ${color.white};
  font-size: 1rem;
  line-height: auto;
  transition: width 200ms ease-out;

  &:hover {
    transform: none;
    background: #e30d76bb;
  }
`;

const Button = styled.button<{
  variant: BetterButtonProps["variant"];
  disabled: boolean;
  $width: string;
  isMinimised: boolean;
  $minimisedWidth: string;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 2rem;
  gap: 1.5rem;
  position: relative;
  border-radius: 0.5rem;
  border: none;
  width: ${(p) => p.$width || "fit-content"};
  font-family: ${fonts.body};
  font-style: normal;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 2rem;
  letter-spacing: 0.075rem;
  cursor: pointer;
  transition: 200ms ease-out;
  &:hover {
    transform: translate(-0.5rem, -0.5rem);
  }

  ${(p) =>
    p.variant === "secondary"
      ? secondary
      : p.variant === "inverted"
        ? inverted
        : p.variant === "purple"
          ? purple
          : p.variant === "menu"
            ? menu
            : p.variant === "purpleInverted"
              ? purpleInverted
              : primary}

  ${(p) =>
    p.disabled &&
    `
    opacity: 0.3;
    cursor: not-allowed;

    &:hover {
      transform: translate(0rem, 0rem);
      box-shadow: none;
    }
  `}
  ${(p) =>
    p.isMinimised &&
    `
    height: 2.5rem;
    width: ${(p) => p.$minimisedWidth || "10.1rem"};
    padding: 0.188rem 1rem 0 1rem;
    &:hover {
      transform: none;
    }
  `}
`;
