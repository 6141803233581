import { FC } from "react";
import { color as themeColor } from "@styles";
import styled from "styled-components";
import { mobile } from "components/templates/Breakpoints";

interface Props {
  color?: string;
  isMinimized?: boolean;
  id?: string;
}

export const DaiIchiYuLifeDoubleLogo: FC<React.PropsWithChildren<Props>> = ({
  color = themeColor.P100,
  isMinimized = false,
  id = "YuLifeLogo-title",
}) => (
  <LogoContainer>
    <LogoSVG
      role="img"
      aria-labelledby="DaiIchiLife-title"
      isMinimized={isMinimized}
      fill="none"
      height="72"
      viewBox="0 0 159 72"
      width="159"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id={id}>Dai-Ichi Life Logo</title>
      <clipPath id="a">
        <path d="m0 0h158.41v72h-158.41z" />
      </clipPath>
      <g clip-path="url(#a)">
        <path
          d="m79.3428 71.9997 56.3742-4.9923c9.126-.9887 15.626-7.4075 15.626-16.6628s-6.5-16.0468-16.128-16.841l-55.8722-4.9276-55.8722 4.9276c-9.6118.7942-16.12783 7.6344-16.12783 16.841 0 9.2067 6.51603 15.6741 15.64163 16.6628l56.3746 4.9923"
          fill="#c7000d"
        />
        <g fill="#231916">
          <path d="m32.1435 15.9822v-4.2954h3.1445c.8429 0 1.5723.0324 2.2207.1135v-2.22066c-.5836.08104-1.3292.11346-2.2369.11346h-3.1121v-3.67942h3.8253c.8591 0 1.6047.04862 2.3827.11346v-2.20442c-.7456.08105-1.5074.11347-2.4313.11347h-3.7605v-1.53985c0-1.10221.0325-1.750563.1135-2.4313376h-2.3179c.0811.7294016.1135 1.3777576.1135 2.4313376v1.53985h-2.9338c.2431-.7132.389-1.21567.47-1.50743.1297-.51869.308-1.03737.3566-1.18325l-2.0585-.583524c-.0648.599734-.1459 1.021164-.389 1.880234-.5997 2.23683-1.7182 4.32778-3.0635 5.86763.5835.45385 1.0212.95632 1.475 1.68575 1.005-1.26432 1.7182-2.47999 2.48-4.18192h3.6794v3.67942h-2.8041c-.9726 0-1.5561-.01621-2.1558-.09725v2.18815c.6321-.081 1.3291-.1134 2.2044-.1134h2.7717v4.2954h-4.6357c-.9564 0-1.7506-.0487-2.5286-.1459v2.3016c.8428-.0972 1.6209-.1296 2.5772-.1296h11.0707c.9725 0 1.6209.0324 2.4637.1296v-2.3016c-.7132.0972-1.4912.1459-2.5124.1459h-4.3602" />
          <path d="m54.9973 15.9012v-1.8641h1.8154c.8266 0 1.3291.0163 1.783.0811v-2.0748c-.4377.0811-.9077.0973-1.783.0973h-1.8154v-.1135c0-.5186 0-1.0535.081-1.5236h-2.0585c.0324.3566.081.9401.081 1.5398v.0973h-1.5074c-.9563 0-1.3778 0-1.7668-.081v2.0423c.5187-.0486.8591-.0649 1.7668-.0649h1.5074v1.8641h-2.334c-.8915 0-1.4751 0-1.9289-.0811v2.1558c.5835-.081 1.1832-.1297 1.9775-.1297h6.5484c.7618 0 1.3615.0487 1.9775.1297v-2.1558c-.5025.0649-1.0698.0811-1.9289.0811h-2.3989" />
          <path d="m51.5122 8.15319c-.6483 0-1.2156-.04863-1.7019-.09725v2.13956c.5349-.0648 1.167-.0972 1.6371-.0972h5.7218c.7131 0 1.3291.0486 1.9126.0972v-2.12335c-.4863.06483-.8753.08104-1.7181.08104h-2.3341v-1.60468h1.9288c.7294 0 1.2157.01621 1.702.08104v-2.05853c-.4863.08105-.8753.09726-1.702.09726h-1.9288c0-.69159.027-1.17785.081-1.45881h-2.0585c.0324.32418.081.81045.081 1.45881h-1.1184c-.7618 0-1.1994-.00001-1.6533-.09726v2.05853c.4863-.06483.9725-.08104 1.6533-.08104h1.1184v1.60468h-1.5722" />
          <path d="m49.3077 2.78784h7.9099c.6808 0 1.4264.03242 2.0423.09725v-2.155781c-.5835.081045-1.1346.097254-2.0747.097254h-7.991c-.8266 0-1.2643-.016209-1.8316-.081045.0486.761822.081 1.880232.081 2.463752l-.0324 3.6308c-.0324 2.95002-.3404 5.68933-.7942 7.26163-.3242 1.0698-.6808 1.945-1.167 2.7393.778.5349 1.0373.8104 1.6046 1.6533.6484-1.1995 1.0374-2.2206 1.3778-3.6957.5673-2.334.8591-5.42994.8591-9.64426v-2.3665" />
          <path d="m44.4774 10.6172c-.697 3.1283-1.3291 4.9923-2.0909 6.1107l1.6533 1.6209c.6321-1.4426 1.3291-3.8253 1.9451-6.6456l-1.5237-1.086" />
          <path d="m45.5643 4.44093 1.394-1.55605c-.8429-1.10221-1.4102-1.6371-2.6907-2.625847l-1.4102 1.426387c1.2967.98874 1.702 1.42638 2.7069 2.75551z" />
          <path d="m45.2711 9.45 1.2481-1.70193c-1.167-1.23188-1.7019-1.65331-2.9824-2.49618l-1.2643 1.55606c1.3129.9077 1.8964 1.44259 2.9986 2.64205z" />
          <path d="m70.9788 2.69071c-4.8951 0-7.7478 3.84151-7.7478 7.99099 0 3.5822 1.4588 5.2679 3.339 5.2679 1.3615 0 2.5124-.7132 3.3877-2.6745.9887-2.1233 1.6695-4.87885 1.7829-8.50965 2.7393.21072 4.0847 2.56101 4.0847 4.84647 0 2.85278-1.394 4.66818-4.652 5.75418l1.086 1.9937c3.5498-1.2643 5.6407-3.7929 5.6407-7.73168 0-3.93876-2.6258-6.95362-6.9212-6.95362m-2.869 9.7416c-.4862 1.0211-.9563 1.2643-1.4912 1.2643-.6321 0-1.3777-.6646-1.3777-3.0311 0-2.56102 1.4263-5.43 4.4736-5.81901-.1296 3.17694-.7132 5.60831-1.6047 7.56951z" />
          <path d="m80.4932 15.2847 2.0099 1.5074c2.3665-3.5984 3.8415-7.53712 4.425-12.57809l-2.3179-.55111c-.6483 4.9113-1.9937 8.5421-4.117 11.638" />
          <path d="m95.908 0c-1.313 0-2.3179 1.02116-2.3179 2.33408 0 1.31293 1.0049 2.33409 2.3179 2.33409 1.3129 0 2.3178-1.02116 2.3178-2.33409 0-1.31292-1.0049-2.33408-2.3178-2.33408zm0 3.69563c-.7781 0-1.3454-.58352-1.3454-1.36155 0-.77802.5835-1.361545 1.3454-1.361545.7618 0 1.3453.583525 1.3453 1.361545 0 .77803-.5835 1.36155-1.3453 1.36155z" />
          <path d="m90.2179 4.23019c1.9612 3.74426 3.4849 8.12071 4.425 12.51331l2.4475-1.0536c-1.0697-4.652-2.8041-8.97975-4.8626-12.5295l-2.0261 1.06979" />
          <path d="m112.556 8.13702h-12.7081v2.31788h12.7081z" />
          <path d="m158.41 8.13702h-12.708v2.31788h12.708z" />
          <path d="m118.828.843079h-2.318v17.116621h2.318v-8.81766c1.994.82666 4.312 2.00986 6.322 3.37146l.989-2.2206c-2.399-1.57229-5.041-2.83658-7.311-3.61461z" />
          <path d="m136.901.956543h-2.285v5.073387h-6.338v2.23683h6.305c-.259 3.77664-1.766 5.93244-4.765 7.48854l1.475 2.1071c3.501-1.8316 5.43-5.122 5.576-9.59564h6.062v-2.23683h-6.03z" />
          <path d="m14.5232 8.97975h-14.5232v2.35025h18.4295v-2.35025l-2.8527-1.2643z" />
        </g>
        <path
          d="m76.8959 48.1893h-23.6001c-.1701 0-.308.1379-.308.3079v2.3341c0 .1701.1379.308.308.308h23.6001c.1701 0 .308-.1379.308-.308v-2.3341c0-.17-.1379-.3079-.308-.3079z"
          fill="#fff"
        />
        <path
          d="m26.7274 43.1969c.2432-.2918.5025-.6321.7619-.9887h2.0585v1.864c0 .1783.1459.3079.308.3079h1.8478c.1783 0 .308-.1458.308-.3079v-1.8478h2.4475c.1783 0 .308-.1459.308-.3242v-1.5074c0-.1783-.1459-.308-.308-.308h-5.6407l.1297-.2431c.1783-.3566.3241-.6484.5024-1.0536.0325-.0973.0325-.1945 0-.2756-.0486-.081-.1296-.1459-.2269-.1621l-2.0909-.3079c-.1459-.0162-.2918.0648-.3404.1945-.6322 1.6047-1.7344 3.258-3.0149 4.5709-.0972.0972-.1134.2269-.0648.3404.0486.1134.1621.1945.2917.1945h2.4962c.0973 0 .1783-.0486.2432-.1135z"
          fill="#fff"
        />
        <path
          d="m47.1693 40.0684h-7.8451c.2269-.5025.3728-.8753.5673-1.4102.0324-.0972.0162-.1945-.0324-.2755-.0487-.0811-.1297-.1297-.227-.1459l-2.0747-.2593c-.1621-.0162-.2918.081-.3404.2269-.5025 1.7019-1.3291 3.2904-2.3665 4.6033-.081.0973-.0973.227-.0324.3404.0486.1135.1621.1783.2917.1783h2.3179c.0973 0 .2107-.0486.2594-.1459.1621-.2431.389-.5835.5997-.9725h1.6209v1.864c0 .1783.1459.308.308.308h1.8478c.1783 0 .3079-.1459.3079-.308v-1.864h4.7979c.1783 0 .308-.1459.308-.308v-1.5074c0-.1783-.1459-.308-.308-.308z"
          fill="#fff"
        />
        <path
          d="m46.8116 53.1487h-9.5957v-2.1882h8.3152c.1783 0 .3079-.1459.3079-.308v-5.43c0-.1783-.1459-.3079-.3079-.3079h-19.5642c-.1783 0-.308.1458-.308.3079v1.475c0 .1783.1459.308.308.308h8.5259v1.9613h-7.9586c-.1621 0-.2918.1134-.308.2593l-.9239 5.6407c0 .0973 0 .1783.0649.2594.0648.0648.1458.1134.2431.1134h6.824c-2.3503 2.0099-5.3328 3.0311-8.2018 3.8415-.1296.0325-.2269.1621-.2269.308v1.9289c0 .0972.0486.1783.1135.2431.0648.0648.1621.0811.2593.0648.3566-.0648.8753-.1783 1.3616-.3241 2.8203-1.0374 6.3701-2.5448 8.769-5.122v6.0783c0 .1783.1459.308.308.308h2.0747c.1783 0 .308-.1459.308-.308v-7.0347h7.0346c-.1783 1.3292-.3403 2.091-.5186 2.561-.2108.616-.4863.7781-1.3616.7781-.6646 0-1.5398-.0811-2.7555-.2432-.0973 0-.1783 0-.2431.0811-.0649.0648-.1135.1459-.1135.2431v1.783c0 .1459.0972.2755.2431.308.0973.0162.1783.0324.3242.0486 1.1833.1134 2.3827.1783 3.1932.1783 1.9288 0 2.7069-.4214 3.2255-1.702.4539-1.1508.7294-2.9824.8915-5.7865 0-.0811-.0324-.1783-.081-.227-.0649-.0648-.1459-.0972-.2269-.0972zm-9.5957-4.1819v-1.9613h5.9v1.9613zm-2.7069 1.9937v2.1882h-6.0135l.2755-2.1882h5.7218z"
          fill="#fff"
        />
        <path
          d="m106.444 59.049h-9.725v-5.8352h7.926c.178 0 .308-.1459.308-.308v-1.9451c0-.1783-.146-.3079-.308-.3079h-7.926v-5.3166h8.72c.179 0 .308-.1458.308-.3079v-1.9937c0-.1783-.145-.308-.308-.308h-8.72v-4.3116c0-.1783-.1459-.3079-.3079-.3079h-2.2531c-.1783 0-.3079.1458-.3079.3079v4.3116h-4.6034c.6322-1.5885 1.005-2.9176 1.1671-3.566.0162-.0972 0-.1945-.0649-.2755-.0648-.0811-.1459-.1135-.2431-.1135h-2.3665c-.1459 0-.2756.0973-.308.2432 0 .0486-1.4102 5.2841-4.5709 9.158-.081.0972-.0972.2269-.0486.3404.0486.1134.1621.1783.2917.1783h2.7231c.0973 0 .1945-.0487.2594-.1297.7132-.9401 1.3615-1.9937 1.9775-3.1932h5.8028v5.3166h-8.4287c-.1783 0-.3079.1459-.3079.3079v1.9451c0 .1783.1458.308.3079.308h8.4287v5.8352h-10.0496c-.1783 0-.3079.1459-.3079.308v1.9937c0 .1783.1458.3079.3079.3079h22.6603c.178 0 .308-.1459.308-.3079v-1.9937c0-.1783-.146-.308-.308-.308z"
          fill="#fff"
        />
        <path
          d="m132.654 48.5294h-8.883c-.178 0-.308.1459-.308.308v13.4209c0 .1783.146.308.308.308h2.075c.178 0 .308-.1459.308-.308v-11.4759h4.182v5.1545c0 .5349-.114.6645-.551.6645-.503 0-1.394-.0648-2.318-.1783-.097 0-.178.0162-.243.0811-.065.0648-.114.1459-.114.2431v1.702c0 .162.13.2917.276.3079h.178c.908.1459 1.832.1945 2.966.1945 1.816 0 2.432-.5673 2.432-2.2692v-7.8613c0-.1783-.146-.308-.308-.308z"
          fill="#fff"
        />
        <path
          d="m121.227 48.6102h-7.748c-.178 0-.308.1458-.308.3079v8.8987c0 .1783.146.308.308.308h7.748c.179 0 .308-.1459.308-.308v-8.8987c0-.1783-.146-.3079-.308-.3079zm-5.495 7.2778v-5.0572h3.356v5.0572z"
          fill="#fff"
        />
        <path
          d="m134.47 44.4936c-4.75-1.6208-7.943-3.6145-10.05-6.289-.065-.0811-.146-.1135-.243-.1135h-3.047c-.098 0-.195.0487-.26.1297-2.285 3.1932-5.349 5.3651-9.628 6.8564-.129.0486-.211.1621-.211.2917v2.1396c0 .0973.049.1945.13.2593.049.0487.13.0649.195.0649h.081l.454-.1297c1.977-.697 3.955-1.6695 5.575-2.7393v1.1346c0 .1783.146.308.308.308h10.066c.178 0 .308-.1459.308-.308v-1.3615c1.589.9239 3.55 1.7992 5.625 2.561.064.0324.194.081.453.1783.098.0324.211.0324.292-.0324.081-.0649.146-.1621.146-.2594v-2.3665c0-.1296-.081-.2593-.211-.2917zm-11.817-4.2143c1.184 1.394 2.594 2.5772 4.539 3.8415h-8.51c1.605-1.167 2.853-2.3827 3.971-3.8415z"
          fill="#fff"
        />
      </g>
    </LogoSVG>
    <SVG
      xmlns="http://www.w3.org/2000/svg"
      width="2"
      height="66"
      viewBox="0 0 2 66"
      fill="none"
      isMinimized={isMinimized}
    >
      <path d="M0.93808 0.176941V65.823" stroke="#D9D9D7" stroke-width="1.62089" />
    </SVG>
    <LogoSVG
      role="img"
      aria-labelledby="YuLifeLogo-title"
      isMinimized={isMinimized}
      fill="none"
      height="72"
      viewBox="0 0 136 72"
      width="136"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id={id}>YuLife Logo</title>
      <clipPath id="a">
        <path d="m.46637.340393h135.507v71.3192h-135.507z" />
      </clipPath>
      <g clip-path="url(#a)" fill="#e30d76">
        <path d="m96.2837 22.3068c-1.4977 0-2.6744-1.1055-2.6744-2.6388 0-1.4977 1.1767-2.6388 2.6744-2.6388s2.6745 1.1411 2.6745 2.6388c0 1.5333-1.1768 2.6388-2.6745 2.6388zm-2.0682 4.3861h3.9225v21.5741h-3.9225z" />
        <path d="m108.194 23.8758v2.8171h6.882v3.245h-6.882v18.3291h-3.887v-18.2934h-3.031v-3.245h3.031v-2.8528c0-4.3861 3.388-7.3102 7.845-7.3102 1.248 0 2.817.2139 3.21.3922l-.179 3.3877c-.535-.214-1.89-.3923-2.817-.3923-2.888 0-4.172 1.783-4.172 3.9226z" />
        <path d="m135.402 38.4962h-16.118c.321 4.1008 2.639 6.704 6.419 6.704s5.242-1.9969 5.634-3.7443h3.923c-.82 3.9226-3.816 7.0606-9.593 7.0606-6.739 0-10.377-4.6357-10.377-10.9475 0-6.4543 4.279-11.0544 10.306-11.0544 6.026 0 9.806 4.1008 9.806 11.0544zm-16.047-2.8528h12.089c-.499-3.8869-2.603-5.8482-5.848-5.8482-3.281 0-5.599 2.2466-6.241 5.8482z" />
        <path d="m89.758 44.5227-.2139.0713c-.6419.2497-1.3551.4636-2.0326.4636-1.0698 0-2.9241-.4636-2.9241-3.5659v-24.4625h-3.8513v24.4268c0 5.2063 3.2094 7.0249 6.2405 7.0249 1.355 0 2.7814-.3566 3.9225-.9984l.214-.107z" />
        <path d="m68.9328.340393h-65.61366c-1.56902 0-2.85277 1.283747-2.85277 2.852767v65.61364c0 1.5691 1.28375 2.8528 2.85277 2.8528h65.61366c1.569 0 2.8528-1.2837 2.8528-2.8528v-65.61364c0-1.56902-1.2838-2.852767-2.8528-2.852767zm-47.9978 57.697207h-4.5288s4.4931-10.4839 4.9924-11.732l-9.8421-23.4283h4.7071l7.4172 18.1507 7.4172-18.1507h4.7427zm39.3682-20.1476c0 9.4854-6.1691 10.5909-10.8049 10.5909-7.3815 0-10.8048-3.352-10.8048-10.5909v-15.0127h4.3148v15.0127c0 2.9954.7488 6.4187 6.49 6.4187s6.4901-3.4233 6.4901-6.4187v-15.0127h4.3148z" />
      </g>
    </LogoSVG>
  </LogoContainer>
);

const LogoSVG = styled.svg<{ isMinimized?: boolean }>`
  transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  width: ${(p) => (p.isMinimized ? "5rem" : "7rem")};

  ${mobile} {
    width: ${(p) => (p.isMinimized ? "3.5rem" : "8rem")};
  }

  @media only screen and (max-width: 30rem) {
    width: ${(p) => (p.isMinimized ? "3rem" : "5.125rem")};
    height: auto;
  }
`;

const SVG = styled.svg<{ isMinimized?: boolean }>`
  transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  height: ${(p) => (p.isMinimized ? "3.5rem" : "4.5rem")};

  ${mobile} {
    height: ${(p) => (p.isMinimized ? "1.5rem" : "8rem")};
  }

  @media only screen and (max-width: 30rem) {
    height: ${(p) => (p.isMinimized ? "1.5rem" : "4.5rem")};
`;

const LogoContainer = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;