import { Link } from "@components/atoms";
import { FlagIcon, FlagWrap, LI2 } from "./MobileNav.styles";
import { Anchor } from "../../Navbar.styles";
import { YuLifeLocale } from "@hooks";
import { FC } from "react";

interface MobileNavLinkProps {
  handleLocaleSwitch?: (locale: YuLifeLocale) => void;
  link: string;
  locale?: YuLifeLocale;
  localeLink?: boolean;
  text: string;
  handleClick: (link: string) => void;
  external?: boolean;
}

export const MobileNavLink: FC<MobileNavLinkProps> = ({
  handleLocaleSwitch,
  link,
  locale,
  localeLink,
  text,
  handleClick,
  external,
}) => {
  if (external) {
    return (
      <LI2 onClick={() => handleClick(link)}>
        <Anchor href={link} target="_blank" rel="noopener noreferrer">
          {text}
        </Anchor>
      </LI2>
    );
  }

  if (localeLink) {
    return (
      <LI2 onClick={() => handleLocaleSwitch(locale)}>
        <FlagWrap>
          <Anchor>{text}</Anchor>
          <FlagIcon alt={text} src={`/static/icons/flags/${link}.svg`} />
        </FlagWrap>
      </LI2>
    );
  }

  return (
    <LI2 onClick={() => handleClick(link)}>
      <Link passHref href={link} legacyBehavior>
        <Anchor>{text}</Anchor>
      </Link>
    </LI2>
  );
};
