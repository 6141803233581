import React, { FC } from "react";
import { Container, LeftLine, RightLine } from "./MobileNavArrow.styles";

const MobileNavArrow: FC<React.PropsWithChildren<{ isOpen?: boolean }>> = ({ isOpen }) => {
  return (
    <Container>
      <LeftLine isOpen={isOpen}></LeftLine>
      <RightLine isOpen={isOpen}></RightLine>
    </Container>
  );
};

export default MobileNavArrow;
