import { MegaMenuLargeBlock } from "components/molecules/MegaMenuLargeBlock";
import { MegaMenuSmallBlock } from "components/molecules/MegaMenuSmallBlock";
import { Link } from "@components/atoms";
import { FC } from "react";
import { StaticRoutes } from "@routes";
import styled from "styled-components";
import { Column, TextBlock, Asset, Container, ResizableContainer } from "./styles";
import { useLocaleData } from "hooks/useLocaleData";
import data from "./translations";
import { useTranslations } from "next-intl";

export const MegaMenuAdviser: FC = () => {
  const t = useTranslations("shared/mega-menu");
  const { adviser } = useLocaleData(data);
  return (
    <ResizableContainer>
      <StyledContainer>
        <Column>
          <TextBlock>
              <MegaMenuLargeBlock
                title={t<string>("adviser.left.title")}
                desc={t<string>("adviser.left.desc")}
              />
          </TextBlock>
        </Column>
        <MiddleColumn>
          {adviser
            .filter((item) => item.isLive === undefined || item.isLive === true)
            .map((item) => (
              <Link key={item.key} href={item.href}>
                <MegaMenuSmallBlock title={item.title} desc={item.desc} />
              </Link>
            ))}
        </MiddleColumn>
      </StyledContainer>
    </ResizableContainer>
  );
};

const StyledContainer = styled(Container)`
  max-width: 56rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
`;

const MiddleColumn = styled(Column)`
  margin: 1.5rem 0 1.5rem;
  max-width: 24rem;
  min-width: 24rem;
`;
