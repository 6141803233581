import { FC } from "react";
import { color as themeColor } from "@styles";
import styled from "styled-components";

interface Props {
  color?: string;
  isMinimized?: boolean;
  id?: string;
}

export const Logo: FC<React.PropsWithChildren<Props>> = ({
  color = themeColor.P100,
  isMinimized = false,
  id = "YuLifeLogo-title",
}) => (
  <SVG
    role="img"
    aria-labelledby={id}
    $isMinimized={isMinimized}
    viewBox="0 0 138 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title id={id}>YuLife Logo</title>
    <path
      d="M97.2775 22.1761C95.757 22.1761 94.5623 21.0601 94.5623 19.5121C94.5623 18.0001 95.757 16.8481 97.2775 16.8481C98.798 16.8481 99.9927 18.0001 99.9927 19.5121C99.9927 21.0601 98.798 22.1761 97.2775 22.1761ZM95.1777 26.6041H99.16V48.3841H95.1777V26.6041Z"
      fill={color}
    />
    <path
      d="M109.369 23.7601V26.6041H116.356V29.8801H109.369V48.3841H105.423V29.9161H102.346V26.6401H105.423V23.7601C105.423 19.3321 108.862 16.3801 113.388 16.3801C114.655 16.3801 116.248 16.5961 116.646 16.7761L116.465 20.1961C115.922 19.9801 114.546 19.8001 113.605 19.8001C110.673 19.8001 109.369 21.6001 109.369 23.7601Z"
      fill={color}
    />
    <path
      d="M136.992 38.52H120.628C120.954 42.66 123.307 45.288 127.145 45.288C130.982 45.288 132.467 43.272 132.865 41.508H136.847C136.015 45.468 132.974 48.636 127.109 48.636C120.266 48.636 116.574 43.956 116.574 37.584C116.574 31.068 120.918 26.424 127.036 26.424C133.155 26.424 136.992 30.564 136.992 37.584V38.52ZM120.701 35.64H132.974C132.467 31.716 130.331 29.736 127.036 29.736C123.706 29.736 121.352 32.004 120.701 35.64Z"
      fill={color}
    />
    <path
      d="M90.6523 44.6041L90.4351 44.6761C89.7834 44.9281 89.0594 45.1441 88.3715 45.1441C87.2854 45.1441 85.4029 44.6761 85.4029 41.5441V16.8481H81.493V41.5081C81.493 46.7641 84.7512 48.6001 87.8285 48.6001C89.2042 48.6001 90.6523 48.2401 91.8108 47.5921L92.028 47.4841L90.6523 44.6041Z"
      fill={color}
    />
    <path
      d="M69.5098 0H2.89624C1.30331 0 0 1.296 0 2.88V69.12C0 70.704 1.30331 72 2.89624 72H69.5098C71.1027 72 72.406 70.704 72.406 69.12V2.88C72.406 1.296 71.1027 0 69.5098 0ZM20.7805 58.248H16.1827C16.1827 58.248 20.7443 47.664 21.2512 46.404L11.2591 22.752H16.0379L23.5682 41.076L31.0984 22.752H35.9134L20.7805 58.248ZM60.7486 37.908C60.7486 47.484 54.4855 48.6 49.7791 48.6C42.2851 48.6 38.8096 45.216 38.8096 37.908V22.752H43.1902V37.908C43.1902 40.932 43.9505 44.388 49.7791 44.388C55.6078 44.388 56.3681 40.932 56.3681 37.908V22.752H60.7486V37.908Z"
      fill={color}
    />
  </SVG>
);

const SVG = styled.svg<{ $isMinimized?: boolean }>`
  transition: all 0.3s cubic-bezier(0.4, 0.01, 0.165, 0.99);
  height: ${(p) => (p.$isMinimized ? "2rem" : "4.5rem")};

  @media only screen and (max-width: 30rem) {
    width: ${(p) => (p.$isMinimized ? "3rem" : "5.125rem")};
    height: auto;
  }
`;
