import styled from "styled-components";
import { color } from "@styles";

export const NavTitle = styled.strong`
  color: ${color.B1100};
  display: inline-block;
  padding: 3rem 0.5rem 1rem 0.5rem;
`;

export const NavListItem = styled.li`
  padding: 0.5rem 0.5rem;
  font-weight: 400;
  font-size: 0.875rem;
  color: ${color.B1100};
  text-decoration: none;
  line-height: 1rem;
  letter-spacing: 0.075rem;
  display: flex;
  justify-content: flex-end;

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    ::-moz-selection {
      background: ${color.P20};
    }
    ::selection {
      background: ${color.P20};
    }
  }
`;

export const NavLinksGrid = styled.nav<{ numberOfSections: number }>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.numberOfSections}, 1fr);
  text-align: right;

  @media only screen and (max-width: 55rem) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media only screen and (max-width: 40rem) {
    grid-template-columns: 1fr 1fr;
  }
`;
