import styled from "styled-components";
import { color as themeColor, fonts } from "@styles";
import { mobile, tablet } from "../templates/Breakpoints";
import { createElement, FC, ReactNode } from "react";

export enum HeadingSize {
  H1Large,
  H1,
  H2,
  H3,
  H4,
  H5,
}

type Alignment = "left" | "center" | "right" | "inherit";

interface Props {
  id?: string;
  pt?: string | string[];
  pb?: string | string[];
  align?: Alignment | Alignment[];
  fontSize?: string | string[];
  color?: string;
  lineheight?: string;
}

interface PropsExtended extends Props {
  size?: HeadingSize | number;
  style?: object;
  className?: string;
}

const headerStyles: Props[] = [];

headerStyles[HeadingSize.H1Large] = {
  fontSize: ["5.5rem", "4rem", "3rem"],
  lineheight: "0.91",
};

headerStyles[HeadingSize.H1] = {
  fontSize: ["3.5rem", "3rem", "2.5rem"],
  lineheight: "1.14",
};

headerStyles[HeadingSize.H2] = {
  fontSize: ["3rem", "3rem", "2.5rem"],
  lineheight: "1.17",
};

headerStyles[HeadingSize.H3] = {
  fontSize: "2.5rem",
};

headerStyles[HeadingSize.H4] = {
  fontSize: "2rem",
};

headerStyles[HeadingSize.H5] = {
  fontSize: "1.5rem",
};

export const Heading = styled(({ tag, children, ...p }) => createElement(tag, p, children))<Props>`
  margin: 0;
  letter-spacing: 0rem;
  font-family: ${fonts.heading};
  font-weight: normal;

  ${(p) => getStyle("padding-top", p.pt, Breakpoints.all, "0")}
  ${(p) => getStyle("padding-bottom", p.pb, Breakpoints.all, "0")}
  ${(p) => getStyle("text-align", p.align, Breakpoints.all, "left")}
  ${(p) => getStyle("font-size", p.fontSize, Breakpoints.all, "3.5rem")}
  color: ${(p) => p.color || "#640038"};
  line-height: ${(p) => p.lineheight || "1.14"};

  ${tablet} {
    ${(p) => getStyle("padding-top", p.pt, Breakpoints.tablet)}
    ${(p) => getStyle("padding-bottom", p.pb, Breakpoints.tablet)}
    ${(p) => getStyle("text-align", p.align, Breakpoints.tablet)}
    ${(p) => getStyle("font-size", p.fontSize, Breakpoints.tablet, "3rem")}
  }

  ${mobile} {
    ${(p) => getStyle("padding-top", p.pt, Breakpoints.mobile)}
    ${(p) => getStyle("padding-bottom", p.pb, Breakpoints.mobile)}
    ${(p) => getStyle("text-align", p.align, Breakpoints.mobile)}
    ${(p) => getStyle("font-size", p.fontSize, Breakpoints.mobile, "2.5rem")}
  }

  ::-moz-selection {
    background: ${themeColor.P20};
  }
  ::selection {
    background: ${themeColor.P20};
  }
`;

Heading.defaultProps = {
  tag: "h1",
};

const getHeader = (
  {
    size = HeadingSize.H1,
    style,
    className,
    id,
    pt,
    pb,
    align,
    fontSize,
    color,
    lineheight,
  }: PropsExtended,
  children?: ReactNode,
  tag?: string,
) => {
  const headerStyle = headerStyles[size] || {};
  return (
    <Heading
      tag={tag}
      style={style}
      className={className}
      id={id}
      pt={pt || headerStyle.pt}
      pb={pb || headerStyle.pb}
      color={color || headerStyle.color}
      align={align || headerStyle.align}
      fontSize={fontSize || headerStyle.fontSize}
      lineheight={lineheight || headerStyle.lineheight}
    >
      {children}
    </Heading>
  );
};

export const H1: FC<React.PropsWithChildren<PropsExtended>> = (props) => {
  const { children, ...headerProps } = props;
  headerProps.size = headerStyles[headerProps.size] ? headerProps.size : HeadingSize.H1;
  return getHeader(headerProps, children, "h1");
};

export const H1Large: FC<React.PropsWithChildren<PropsExtended>> = (props) => {
  const { children, ...headerProps } = props;
  headerProps.size = headerStyles[headerProps.size] ? headerProps.size : HeadingSize.H1Large;
  return getHeader(headerProps, children, "h1");
};

export const H2: FC<React.PropsWithChildren<PropsExtended>> = (props) => {
  const { children, ...headerProps } = props;
  headerProps.size = headerStyles[headerProps.size] ? headerProps.size : HeadingSize.H2;
  return getHeader(headerProps, children, "h2");
};

export const H3: FC<React.PropsWithChildren<PropsExtended>> = (props) => {
  const { children, ...headerProps } = props;
  headerProps.size = headerStyles[headerProps.size] ? headerProps.size : HeadingSize.H3;
  return getHeader(headerProps, children, "h3");
};

export const H4: FC<React.PropsWithChildren<PropsExtended>> = (props) => {
  const { children, ...headerProps } = props;
  headerProps.size = headerStyles[headerProps.size] ? headerProps.size : HeadingSize.H4;
  return getHeader(headerProps, children, "h4");
};

export const H5: FC<React.PropsWithChildren<PropsExtended>> = (props) => {
  const { children, ...headerProps } = props;
  headerProps.size = headerStyles[headerProps.size] ? headerProps.size : HeadingSize.H5;
  return getHeader(headerProps, children, "h5");
};

enum Breakpoints {
  all,
  tablet,
  mobile,
}

type GetStyleType = (
  property: string,
  value?: string | string[],
  breakpoint?: Breakpoints | number,
  defaultValue?: string,
) => string;

const getStyle: GetStyleType = (property, value, breakpoint = Breakpoints.all, defaultValue) => {
  if (value) {
    if (typeof value === "string") {
      if (value !== "") {
        return `${property}: ${value};`;
      }

      if (defaultValue) {
        return `${property}: ${defaultValue};`;
      }

      return "";
    }

    if (!value.length) {
      if (defaultValue) {
        return `${property}: ${defaultValue};`;
      }

      return "";
    }

    const val = (breakpoint > 1 && value[2]) || (breakpoint > 0 && value[1]) || value[0];

    if (val) {
      return `${property}: ${val};`;
    }
  }

  if (defaultValue) {
    return `${property}: ${defaultValue};`;
  }

  return "";
};
