import { MegaMenuLargeBlock } from "components/molecules/MegaMenuLargeBlock";
import { MegaMenuSmallBlock } from "components/molecules/MegaMenuSmallBlock";
import { Link } from "@components/atoms";
import { FC } from "react";
import { StaticRoutes } from "@routes";
import styled from "styled-components";
import { Column, TextBlock, Asset, Container, ResizableContainer } from "./styles";
import { useLocaleData } from "hooks/useLocaleData";
import data from "./translations";
import { useTranslations } from "next-intl";
import { useLocale } from "@hooks";

export const MegaMenuAbout: FC = () => {
  const t = useTranslations("shared/mega-menu");
  const { about } = useLocaleData(data);
  const { locale } = useLocale();
  return (
    <>
      {locale === "jp" ? null : (
        <ResizableContainer>
          <StyledContainer>
            <Column>
              <TextBlock>
                <Link href={StaticRoutes.aboutUs}>
                  <MegaMenuLargeBlock
                    title={t<string>("about.left.title")}
                    desc={t<string>("about.left.desc")}
                  />
                </Link>
              </TextBlock>
              <Asset
                width={391}
                height={244}
                src={t<string>("about.left.asset.src")}
                alt={t<string>("about.left.asset.alt")}
                priority
                style={{ width: "auto" }}
              />
            </Column>
            <MiddleColumn>
              {about
                .filter((item) => item.isLive === undefined || item.isLive === true)
                .map((item) => (
                  <Link key={item.key} href={item.href}>
                    <MegaMenuSmallBlock title={item.title} desc={item.desc} />
                  </Link>
                ))}
            </MiddleColumn>
          </StyledContainer>
        </ResizableContainer>
      )}
    </>
  );
};

const StyledContainer = styled(Container)`
  max-width: 56rem;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
`;

const MiddleColumn = styled(Column)`
  margin: 1.5rem 0 1.5rem;
  max-width: 24rem;
  min-width: 24rem;
`;
