import { H3 } from "../atoms/Heading";
import { FC, ReactNode } from "react";
import styled from "styled-components";
import { color, fonts } from "../../styles";
import { P1 } from "components/atoms/Paragraph";

interface MegaMenuSmallBlockProps {
  title: string;
  desc?: string;
  width?: string;
  children?: ReactNode;
  onClick?: () => void;
}

export const MegaMenuSmallBlock: FC<MegaMenuSmallBlockProps> = ({
  title,
  desc,
  width,
  children,
  onClick,
}) => (
  <Container onClick={onClick} width={width}>
    <Heading>
      {children}
      <StyledH3>{title}</StyledH3>
      <svg xmlns="http://www.w3.org/2000/svg" width="10" height="18" fill="none">
        <path
          d="m1 17 8-8-8-8"
          stroke={color.P100}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Heading>
    {desc && <StyledP1>{desc}</StyledP1>}
  </Container>
);

const Container = styled.div<{ width: string }>`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
  gap: 0.25rem;
  width: ${(p) => p.width || "26.875rem"};
  border-radius: 0.5rem;
  transition: 200ms;
  svg {
    opacity: 0;
    transition: 200ms;
  }

  &:hover {
    background: ${color.P10};

    svg {
      transform: translateX(0.5rem);
      opacity: 1;
      path {
        stroke: ${color.P100};
      }
    }
  }
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
  padding: 0;
  gap: 0.5rem;
`;

const StyledH3 = styled(H3)`
  font-family: ${fonts.body};
  font-style: normal;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 2rem;
  color: ${color.B1100};
`;

const StyledP1 = styled(P1)`
  font-family: ${fonts.body};
  color: ${color.B2100};
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0;
  padding-top: 0.25rem;
  letter-spacing: 0.075rem;
`;
