import React from "react";
import { BaseModal } from "../components/BaseModal";
import { ModalContainer, CloseButton } from "../styles";
import { BaseModalProps } from "../types";
import { Cross } from "components/atoms/svgs/Cross";
import { color } from "@styles";

export interface MarketingModalProps extends BaseModalProps {
  exitButtonColor?: string;
}

export const MarketingModal: React.FC<React.PropsWithChildren<MarketingModalProps>> = ({
  closeOnClickOutside,
  showModal,
  closeModal,
  background,
  children,
  exitButtonColor = color.white,
}) => {
  return (
    <BaseModal
      background={background}
      closeModal={closeModal}
      closeOnClickOutside={closeOnClickOutside}
      showModal={showModal}
    >
      <ModalContainer>
        {children}
        <CloseButton onClick={closeModal} aria-label="close">
          <Cross color={exitButtonColor} />
        </CloseButton>
      </ModalContainer>
    </BaseModal>
  );
};
