import { config } from "@utils";
import { MenuName } from "../../../Navigation/components/Navbar/Navbar.types";
import { FooterLinks } from "./links.types";
import { StaticRoutes, ExternalRoutes, DynamicRoutes } from "@routes";

const ukFooterLinks: FooterLinks = [
  {
    title: MenuName.products,
    id: "products-footer-title",
    links: [
      {
        link: StaticRoutes.insuranceGroupHealthInsurance,
        text: "Group Health Insurance",
      },
      {
        link: StaticRoutes.insuranceGroupLifeInsurance,
        text: "Group Life Insurance",
      },
      {
        link: StaticRoutes.insuranceGroupIncomeProtection,
        text: "Group Income Protection",
      },
      {
        link: StaticRoutes.insuranceGroupDentalInsurance,
        text: "Group Dental Insurance",
      },
    ],
  },
  {
    title: MenuName.features,
    id: "features-footer-title",
    links: [
      {
        link: StaticRoutes.featuresEmployeeApp,
        text: "Our App",
      },
      {
        link: StaticRoutes.featuresRewards,
        text: "Rewards & Perks",
      },
      {
        link: StaticRoutes.featuresEmployeeAssistanceProgramme,
        text: "EAP",
      },
      {
        link: StaticRoutes.featuresVirtualGpServices,
        text: "24/7 Virtual GP",
      },
      {
        link: StaticRoutes.featuresSocialImpactBenefits,
        text: "Social Impact (ESG)",
      },
      {
        link: StaticRoutes.featuresHrInsights,
        text: "Reporting",
      },
    ],
  },
  {
    title: MenuName.resources,
    id: "resources-footer-title",
    links: [
      {
        link: StaticRoutes.resourceHub,
        text: "Resource Hub",
      },
      {
        link: StaticRoutes.caseStudies,
        text: "Case Studies",
      },
      {
        link: StaticRoutes.blog,
        text: "Blogs",
      },
      {
        link: StaticRoutes.newsAndEvents,
        text: "News & Events",
      },
      {
        link: ExternalRoutes.faq,
        text: "FAQ",
        external: true,
      },
      {
        link: StaticRoutes.adviser,
        text: "Adviser",
      },
    ],
  },
  {
    title: MenuName.about,
    id: "about-footer-title",
    links: [
      {
        link: StaticRoutes.aboutUs,
        text: "About Us",
      },
      {
        link: StaticRoutes.team,
        text: "Our Team",
      },
      {
        link: StaticRoutes.press,
        text: "Press",
      },
      {
        link: StaticRoutes.careers,
        text: "Careers",
      },
      {
        link: StaticRoutes.contactUs,
        text: "Contact Us",
      },
    ],
  },
  {
    title: MenuName.login,
    id: "login-footer-title",
    links: [
      {
        link: `${config.TEAM_URL}/login`,
        text: "Employers",
        external: true,
      },
      {
        link: `${config.MEMBERS_URL}/login`,
        text: "Employees",
        external: true,
      },
    ],
  },
  {
    title: MenuName.legal,
    id: "legal-footer-title",
    links: [
      {
        link: DynamicRoutes.privacyPolicy,
        text: "Privacy Policy",
      },
      // Rewards policy temporarily removed at the request of legal
      // {
      //   link: DynamicRoutes.rewardsPolicy,
      //   text: "Rewards policy",
      // },
      {
        link: DynamicRoutes.cookiePolicy,
        text: "Cookie Policy",
      },
      {
        link: DynamicRoutes.endUserLicenseAgreementPolicyUK,
        text: "End User Licence Agreement",
      },
    ],
  },
];

export default ukFooterLinks;
