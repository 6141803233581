import Bugsnag from "@bugsnag/js";
import { ApiRoutes } from "@routes";
import { useState } from "react";
import { Logger } from "services/logger";

export const useHubspotForm = () => {
  const [loading, setLoading] = useState(false);
  let results: {
    inlineMessage?: string;
    status?: string;
    message?: string;
    errors?: {
      errorType: string;
      message: string;
    }[];
  } = {};

  const handleFormSubmit = async (name: string, data: any) => {
    setLoading(true);
    let clientIp: string;

    try {
      const ipRequests = [
        fetch("https://api.ipify.org?format=json"),
        fetch("https://ipapi.co/json/"),
      ];

      const ipResponse = await Promise.race(ipRequests).then((response) => response.json());

      const { ip } = await ipResponse;
      clientIp = ip;

      const response = await fetch(ApiRoutes.submitForm, {
        method: "POST",
        body: JSON.stringify({ name, data, ip: clientIp }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      results = await response.json();
    } catch (e) {
      Bugsnag.notify(e);
      Logger.error(JSON.stringify(data));
    } finally {
      setLoading(false);
      return results;
    }
  };

  return {
    loading,
    handleFormSubmit,
  };
};
