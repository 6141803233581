import React from "react";
import styled from "styled-components";
import { Link } from "@components/atoms";
import NextImage from "next/legacy/image";
import { P1 } from "../../atoms/Paragraph";
import { color } from "@styles";
import { StandardSection } from "../../templates/StandardSection";
import Atlantic from "../../../public/static/press/press-bar/atlantic.svg";
import BusinessInsider from "../../../public/static/press/press-bar/business-insider.svg";
import Forbes from "../../../public/static/press/press-bar/forbes.svg";
import Metro from "../../../public/static/press/press-bar/metro.svg";
import Reuters from "../../../public/static/press/press-bar/reuters.svg";
import Sifted from "../../../public/static/press/press-bar/sifted.svg";
import SundayTimes from "../../../public/static/press/press-bar/sunday-times.svg";
import TechCrunch from "../../../public/static/press/press-bar/tech-crunch.svg";
import { color as themeColor } from "@styles";

export const PressBar = ({ heroVariant = false, backgroundColor = "#f2f2fa" }) => {
  if (heroVariant) {
    return (
      <Link href="/press/" passHref>
        <StandardSection background={backgroundColor} pt="2.5rem" pb="2.5rem">
          <PressBarText align="center">We’re proud to have been featured in: </PressBarText>
          <PressLogos>
            <NextImage src={TechCrunch} alt="Tech Crunch" />
            <NextImage src={Forbes} alt="Forbes" />
            <NextImage src={Reuters} alt="Reuters" />
            <NextImage src={Metro} alt="Metro" />
            <NextImage src={Atlantic} alt="The Atlantic" />
            <NextImage src={BusinessInsider} alt="Business Insider" />
            <NextImage src={Sifted} alt="Sifted" />
            <NextImage src={SundayTimes} alt="The Sunday Times" />
          </PressLogos>
        </StandardSection>
      </Link>
    );
  }

  return (
    <Link href="/press/" passHref>
      <StandardSection background={backgroundColor || "#f2f2fa"} pt="2.5rem" pb="2.5rem">
        <PressBarText align="center">We’re proud to have been featured in: </PressBarText>
        <PressLogos>
          <NextImage src={TechCrunch} alt="Tech Crunch" />
          <NextImage src={Forbes} alt="Forbes" />
          <NextImage src={Reuters} alt="Reuters" />
          <NextImage src={Metro} alt="Metro" />
          <NextImage src={Atlantic} alt="The Atlantic" />
          <NextImage src={BusinessInsider} alt="Business Insider" />
          <NextImage src={Sifted} alt="Sifted" />
          <NextImage src={SundayTimes} alt="The Sunday Times" />
        </PressLogos>
      </StandardSection>
    </Link>
  );
};

const PressLogos = styled.div<{ heroVariant?: boolean }>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: ${(p) => (p.heroVariant ? "flex-start" : "center")};
  margin: 1.5rem auto 1rem;
  column-gap: 5rem;
  row-gap: 2.5rem;

  svg {
    margin: 1.5rem 2.5rem;
    height: 2rem;
  }

  @media only screen and (max-width: 25rem) {
    svg {
      margin: 6vw 10vw;
      height: 8vw;
    }
  }
`;

const PressBarText = styled(P1)`
  color: ${color.B1100};
  padding-top: 0;
  padding-bottom: 1.5rem;
`;
