import { FC } from "react";
import styled from "styled-components";
import { color, fonts } from "../../styles";
import { H2 } from "../atoms/Heading";
import { P1 } from "components/atoms/Paragraph";
import Image from "next/image";
import { Truncator } from "components/atoms/Truncator";

interface MegaMenuImageBlockProps {
  title: string;
  subheading: string;
  image: string;
  alt: string;
  pillText: string;
  readTime: string;
}

export const MegaMenuImageBlock: FC<MegaMenuImageBlockProps> = ({
  title,
  subheading,
  image,
  alt,
  pillText,
  readTime,
}) => {
  return (
    <Container>
      <Subheading>
        <Sub>{subheading.toLocaleUpperCase()}</Sub>
      </Subheading>
      <Heading>
        <Truncator $lines={2}>
          <StyledH2>{title}</StyledH2>
        </Truncator>
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" fill="none">
          <path
            d="m1 17 8-8-8-8"
            stroke={color.B1100}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Heading>
      <Asset width={366} height={168} src={image} alt={alt} priority style={{ width: "auto" }} />
      <Footer>
        <Pill>
          <PillText>{pillText}</PillText>
        </Pill>
        <Sub2>{readTime}</Sub2>
      </Footer>
    </Container>
  );
};

const Container = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
  width: 24.875rem;
  border-radius: 0.5rem;
  transition: 200ms;
  svg {
    transition: 200ms;
  }

  &:hover {
    background: #fce7f1;

    svg {
      transform: translateX(0.5rem);
      path {
        stroke: ${color.P100};
      }
    }
  }
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  padding-bottom: 1rem;
  width: 21.875rem;
  gap: 1rem;
  isolation: isolate;
`;

const Subheading = styled.div`
  padding-botton: 0.25rem;
`;

const Sub = styled(P1)`
  font-family: ${fonts.body};
  font-size: 0.875rem;
  line-height: 1.5rem;
  padding: 0;
  color: ${color.B2100};

  ${Container}:hover & {
    color: ${color.B1100};
  }
`;

const StyledH2 = styled(H2)`
  font-family: ${fonts.heading};
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 2rem;
  word-break: break-word;
  max-width: 43rem;
`;

const Asset = styled(Image)`
  border-radius: 0.5rem;
  width: 100%;
  height: auto;
`;

const Footer = styled.div`
  display: flex;
  padding-top: 1rem;
`;

const Pill = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.25rem 1rem;
  min-width: 5rem;
  height: 1.5rem;
  background: ${color.S310};
  border: 0.063rem solid ${color.S330};
  border-radius: 0.5rem;
`;

const PillText = styled.div`
  font-family: ${fonts.body};
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1rem;
  color: ${color.S3100};
`;

const Sub2 = styled(Sub)`
  color: ${color.B1100};
  padding-left: 1rem;
`;
