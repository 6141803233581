import styled from "styled-components";
import { color } from "@styles";
import { NavButton } from "../../Navbar.styles";
import { P1 } from "components/atoms/Paragraph";

const mobileNavBreakpoint = "42rem"

export const FullScreen = styled.div`
  display: block;
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 100vw;
  height: 100vh;
  background: ${color.P100};
  z-index: 99;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const MobileNavLI = styled.li`
  position: relative;
  line-height: 1.6rem;
  color: ${color.white};
  text-align: right;
  letter-spacing: 0.075rem;
  cursor: pointer;
`;

export const LI1 = styled(MobileNavLI)`
  font-weight: bold;
  padding: 1.25rem 3.5rem 0.8rem 0;
  font-size: 1.25rem;
`;

export const StandaloneLI = styled(MobileNavLI)`
  font-weight: bold;
  padding: 1.25rem 0.4rem 0.8rem 0;
  font-size: 1.25rem;
`;

export const LI2 = styled(MobileNavLI)`
  font-weight: 400;
  padding: 0.75rem 3.5rem 0.75rem 0;
  font-size: 1rem;
`;

export const LI3 = styled(MobileNavLI)`
  padding: 1.25rem 0 0.8rem 0;
`;

export const NavButtonMobile = styled(NavButton)`
  font-size: 1.25rem;
  font-weight: bold;
`;

export const FlagIcon = styled.img`
  width: 1.9rem;
  height: 1.4rem;
  padding-left: 0.75rem;
  padding-bottom: 0.125rem;
`;

export const FlagWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const FadeScreen = styled.div`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: black;
  opacity: 70%;
  z-index: 10;
`;

export const MobileReDirectPopup = styled.div`
  position: fixed;
  width: 90vw;
  max-width: 41.5rem;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: ${color.B210};
  text-align: left;
  z-index: 10;
  margin: 0 auto;
  padding: 2rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  border: 1px solid ${color.B230};
  display: flex;
  flex-direction: column;

  @media only screen and (max-width: ${mobileNavBreakpoint}) {
    width: 22rem;
  }
`;

export const MobileHeading = styled(P1)`
  display: none;

  @media only screen and (max-width: ${mobileNavBreakpoint}) {
    display: block;
  }
`;

export const TabletHeading = styled(P1)`
  display: none;

  @media only screen and (min-width: ${mobileNavBreakpoint}) {
    display: block;
  }
`;
