import { shrinkNav } from "components/templates/Breakpoints";
import styled from "styled-components";
import { color } from "../../../styles";
import Image from "next/image";

export const Column = styled.div`
  max-width: 28.3rem;
  min-width: 28.3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0.5rem;
  background: ${color.white};
`;

export const TextBlock = styled.div`
  margin: 1.5rem 1.5rem 0rem 1.5rem;
`;

export const Asset = styled(Image)`
  width: 100%;
  height: auto;
  padding-top: 1rem;
  margin: 0 -0.063rem -0.063rem;
`;

export const ColumnContainer = styled.div<{ maxHeight?: string }>`
  display: flex;
  flex-direction: row;
  border-radius: 0.5rem;
  background: ${color.white};
  max-height: ${(p) => p.maxHeight || "100%"};
`;

export const RightColumn = styled(Column)`
  border-left: 1px solid ${color.B230};
  border-radius: 0 0.5rem 0 0;
`;

export const Container = styled.div`
  display: flex;
  margin: 0 auto;
  align-items: flex-start;
  padding: 0;
  border: 1px solid ${color.B230};
  border-radius: 0.5rem;
  box-shadow: 0.5rem 0.5rem 0rem rgba(92, 87, 87, 0.08);
  background: ${color.white};
`;

export const ResizableContainer = styled.div<{ transform?: string }>`
  ${shrinkNav} {
    transform: ${(p) => p.transform || "scale(0.80)"};
  }
`;
