import { fonts, localeEarthWiggle, localeEarthScaleUp, color } from "@styles";
import { P1 } from "components/atoms/Paragraph";
import { mobile } from "components/templates/Breakpoints";
import styled, { css } from "styled-components";

const SMALL_MOBILE_BREAKPOINT = "@media only screen and (max-width: 23.75rem)";

export const MobileFriendlyP1 = styled(P1)`
  ${mobile} {
    font-size: 1rem;
  }
`;

export const Padding = styled.div<{ spacing: string }>`
  position: relative;
  width: 100%;
  padding: 2.5rem;
  margin: 0 auto;

  & > *:not(:first-child) {
    margin-top: ${(props) => props.spacing};
  }
`;

export const StyledFieldset = styled.fieldset`
  border: 0;
  padding: 0;
  margin: 0;
  min-height: 18rem;
`;

export const StyledLegend = styled.legend`
  font-family: ${fonts.body};
  font-style: normal;
  font-weight: 700;
  font-size: 2rem;
  line-height: 2.5rem;
  letter-spacing: 1.2px;
  margin-bottom: 0.5rem;
  margin-top: 0;
  color: ${color.B1100};

  ${mobile} {
    font-size: 1.5rem;
  }
`;

export const EarthContainer = styled.div<{ closeTiming: number; isClosing?: boolean }>`
  align-self: flex-end;
  display: flex;
  align-items: flex-end;
  position: absolute;
  width: 19.5rem;
  height: 19.5rem;
  left: 500px;
  top: 185px;
  transform: rotate(0) translateY(30px);

  ${mobile} {
    left: auto;
    right: -40px;
    top: 220px;
    width: 185.4px;
    height: 185.41px;
    z-index: -1;
  }

  ${SMALL_MOBILE_BREAKPOINT} {
    display: none;
  }

  animation: ${localeEarthWiggle} 1200ms normal forwards;
  animation-delay: 250ms;

  ${(p) =>
    p.isClosing &&
    css`
      animation: ${localeEarthScaleUp} ${p.closeTiming}ms normal forwards;
    `}
`;
