import { mobile } from "components/templates/Breakpoints";
import Link from "next/link";
import styled from "styled-components";

export const FlexContainer = styled.div`
  display: flex;
  align-items: flex-start;

  ${mobile} {
    flex-direction: column;
    align-items: center;
  }
`;

export const StyledP = styled.p`
  font-size: 1rem;
  margin-right: 2.5rem;

  ${mobile} {
    margin-right: none;
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: underline;
`;
