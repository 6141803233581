import { config } from "@utils";
import { MenuName } from "../../../Navigation/components/Navbar/Navbar.types";
import { FooterLinks } from "./links.types";
import { StaticRoutes, ExternalRoutes, DynamicRoutes } from "@routes";

const jpFooterLinks: FooterLinks = [
  {
    title: MenuName.resourcesJP,
    id: "resources-footer-title",
    links: [
      {
        link: StaticRoutes.blog,
        text: "ブログ",
      },
      {
        link: StaticRoutes.caseStudies,
        text: "他社成功事例",
      },
      {
        link: StaticRoutes.newsAndEvents,
        text: "ニュース・イベント",
      },
    ],
  },
  {
    title: MenuName.featuresJP,
    id: "features-footer-title",
    links: [
      {
        link: StaticRoutes.featuresRewards,
        text: "リワード制度",
      },
      {
        link: StaticRoutes.featuresEmployeeApp,
        text: "YuLifeアプリ",
      },
    ],
  },
  {
    title: MenuName.aboutJP,
    id: "about-footer-title",
    links: [
      {
        link: StaticRoutes.aboutUs,
        text: "YuLifeについて",
      },
    ],
  },
  {
    title: MenuName.loginJP,
    id: "login-footer-title",
    links: [
      {
        link: `${config.TEAM_URL}/login`,
        text: "人事担当の皆さま",
        external: true,
      },
      {
        link: `${config.MEMBERS_URL}/login`,
        text: "従業員の皆さま",
        external: true,
      },
    ],
  },
  {
    title: MenuName.legalJP,
    id: "legal-footer-title",
    links: [
      {
        link: DynamicRoutes.privacyPolicy,
        text: "プライバシーポリシー",
      },
      {
        link: DynamicRoutes.cookiePolicy,
        text: "クッキーポリシー",
      },
      {
        link: DynamicRoutes.endUserLicenseAgreementPolicyUS,
        text: "使用許諾契約書(EULA)",
        locale: "jp",
      },
    ],
  },
];

export default jpFooterLinks;
