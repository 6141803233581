import styled from "styled-components";

const SVGWrapper = styled.div`
  fill: #e30d76;
  transition: 200ms ease-in-out;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 3rem;
  cursor: pointer;

  svg {
    transition: 200ms ease-in-out;
  }

  &:hover {
    svg {
      fill: #f186ba;
    }
  }
`;

export const FacebookIcon = () => (
  <SVGWrapper>
    <svg
      aria-labelledby="facebookIcon-title"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="24"
    >
      <title id="facebookIcon-title">facebook</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.446 24V11.999h3.313l.44-4.136H7.445l.006-2.07c0-1.079.103-1.657 1.652-1.657h2.07V0H7.863C3.882 0 2.48 2.006 2.48 5.38v2.483H0V12h2.48V24h4.966z"
      />
    </svg>
  </SVGWrapper>
);

export const XIcon = () => (
  <SVGWrapper>
    <svg
      aria-labelledby="XIcon-title"
      role="img"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title id="XIcon-title">x</title>
      <path
        d="M13.8948 10.4701L21.3352 2.00049H19.5727L13.1094 9.35303L7.95104 2.00049H2L9.80216 13.1199L2 22.0005H3.76253L10.5835 14.2343L16.0323 22.0005H21.9833M4.39867 3.30207H7.10641L19.5714 20.7629H16.8629"
        fill="#E30D76"
      />
    </svg>
  </SVGWrapper>
);

export const LinkedInIcon = () => (
  <SVGWrapper>
    <svg
      aria-labelledby="linkedIn-title"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="22"
    >
      <title id="linkedIn-title">linkedin</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.167 8.482H0V21.91h4.167V8.482zM5 2.188C4.971.998 4.035.091 2.514.091.994.09 0 .998 0 2.188c0 1.166.965 2.099 2.457 2.099h.028C4.035 4.287 5 3.354 5 2.188zM20 14.387c0-4.03-2.155-5.905-5.029-5.905-2.319 0-3.357 1.274-3.936 2.167V8.79H6.667c.057 1.23 0 13.118 0 13.118h4.368v-7.326c0-.392.028-.783.143-1.064.316-.783 1.035-1.594 2.241-1.594 1.582 0 2.213 1.203 2.213 2.965v7.019H20v-7.522z"
      />
    </svg>
  </SVGWrapper>
);

export const InstagramIcon = () => (
  <SVGWrapper>
    <svg
      aria-labelledby="instagramIcon-title"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
    >
      <title id="instagramIcon-title">instagram</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.512.004h-.825V0C7.959.003 7.436.021 6.085.082 4.658.148 3.883.386 3.367.587a4.543 4.543 0 00-1.683 1.095c-.513.513-.83 1-1.096 1.684-.2.516-.439 1.29-.504 2.718C.014 7.627 0 8.088 0 11.997c0 3.909.014 4.372.084 5.915.065 1.428.304 2.202.504 2.718.266.683.583 1.17 1.096 1.682.512.512 1 .83 1.683 1.095.516.2 1.29.439 2.718.505 1.543.07 2.006.085 5.915.085 3.909 0 4.372-.015 5.915-.085 1.427-.066 2.203-.304 2.718-.505a4.527 4.527 0 001.682-1.095c.512-.512.83-.998 1.095-1.681.2-.516.44-1.29.505-2.717.07-1.544.085-2.007.085-5.914 0-3.906-.015-4.37-.085-5.913-.065-1.427-.304-2.202-.505-2.717a4.543 4.543 0 00-1.095-1.683A4.526 4.526 0 0020.633.592c-.516-.2-1.29-.439-2.718-.504C16.372.018 15.91.004 12 .004h-.488zM7 12a5 5 0 1110.001 0A5 5 0 017 12z"
      />
      <circle cx="19" cy="5" r="2" fill="#F5F5F5" />
    </svg>
  </SVGWrapper>
);
