import { useEffect, FC } from "react";
import styled from "styled-components";
import { color as themeColor } from "@styles";
import LazyLoad, { ILazyLoadInstance } from "vanilla-lazyload";

let lazyLoadInstance: ILazyLoadInstance;

if (process.browser && !lazyLoadInstance) {
  lazyLoadInstance = new LazyLoad({ elements_selector: ".lazy" });
}
export const getPath = (path: string): string => {
  const isPathExternal = /^https?:\/\//i.test(path);
  const beginsWithSlash = /^\//i.test(path);
  const result = isPathExternal ? path : beginsWithSlash ? `/static${path}` : `/static/${path}`;
  return result;
};

export const getImageType = (fileName: string) => {
  switch (true) {
    case fileName.endsWith(".apng"):
      return "image/apng";
    case fileName.endsWith(".bmp"):
      return "image/bmp";
    case fileName.endsWith(".gif"):
      return "image/gif";
    case fileName.endsWith(".ico"):
    case fileName.endsWith(".cur"):
      return "image/x-icon";
    case fileName.endsWith(".jpg"):
    case fileName.endsWith(".jpeg"):
    case fileName.endsWith(".jfif"):
    case fileName.endsWith(".pjpeg"):
    case fileName.endsWith(".pjp"):
      return "image/jpeg";
    case fileName.endsWith(".png"):
      return "image/png";
    case fileName.endsWith(".svg"):
      return "image/svg+xml";
    case fileName.endsWith(".tif"):
    case fileName.endsWith(".tiff"):
      return "image/tiff";
    case fileName.endsWith(".webp"):
      return "image/webp";
    default:
      return null;
  }
};

interface ImageProps {
  path: string;
  width?: string;
  alt?: string;
  border?: boolean;
  borderPosition?: "left" | "right";
  borderColor?: string;
  borderOpacity?: number;
  aspectRatio?: number;
  borderRadius?: string;
  style?: object;
  animate?: boolean;
}

export const Image: FC<React.PropsWithChildren<ImageProps>> = ({
  path: propsPath,
  alt = "",
  width = "",
  border = false,
  borderPosition = "right",
  borderColor = themeColor.S1100,
  borderOpacity = 1,
  aspectRatio,
  borderRadius,
  animate,
  style,
}) => {
  useEffect(() => {
    if (process.browser) {
      lazyLoadInstance.update();
    }
  }, [
    propsPath,
    alt,
    width,
    border,
    borderPosition,
    borderColor,
    borderOpacity,
    aspectRatio,
    borderRadius,
    style,
  ]);

  const path = getPath(propsPath);
  const isStaticImg = /^\/static\/img\//.test(path);
  const isWebp = /\.webp$/.test(path);
  const isSvg = /\.svg$/.test(path);
  const usePicture = isStaticImg && !isWebp && !isSvg;

  if (usePicture) {
    const sourceType = getImageType(path);
    const thumbPath =
      sourceType === "image/jpeg"
        ? `/static/img/thumbnails/${path.substr(12, path.lastIndexOf(".") - 12)}.jpg`
        : null;
    const webpPath = `${path.substr(0, path.lastIndexOf("."))}.webp`;

    if (aspectRatio) {
      return (
        <AspectRatio ratio={aspectRatio}>
          <Picture>
            <source data-srcset={webpPath} type="image/webp" />
            <source data-srcset={path} type={sourceType} />
            <Img
              data-src={path}
              src={thumbPath}
              className="lazy"
              alt={alt}
              borderRadius={borderRadius}
              withRatio
              width={width}
              style={style}
              animate={animate}
            />
          </Picture>
        </AspectRatio>
      );
    }
    switch (border) {
      case true:
        return (
          <Picture>
            <source data-srcset={webpPath} type="image/webp" />
            <source data-srcset={path} type={sourceType} />
            <BorderImg
              data-src={path}
              src={thumbPath}
              className="lazy"
              alt={alt}
              borderPosition={borderPosition}
              borderColor={borderColor}
              borderOpacity={borderOpacity}
              width={width}
            />
          </Picture>
        );
      case false:
      default:
        return (
          <Picture>
            <source data-srcset={webpPath} type="image/webp" />
            <source data-srcset={path} type={sourceType} />
            <Img
              data-src={path}
              src={thumbPath}
              className="lazy"
              alt={alt}
              borderRadius={borderRadius}
              style={style}
              width={width}
              animate={animate}
            />
          </Picture>
        );
    }
  }

  if (aspectRatio) {
    return (
      <AspectRatio ratio={aspectRatio}>
        <Img
          src={path}
          alt={alt}
          borderRadius={borderRadius}
          withRatio
          style={style}
          width={width}
          animate={animate}
        />
      </AspectRatio>
    );
  }
  switch (border) {
    case true:
      return (
        <BorderImg
          src={path}
          alt={alt}
          borderPosition={borderPosition}
          borderColor={borderColor}
          borderOpacity={borderOpacity}
          style={style}
          width={width}
        />
      );
    case false:
    default:
      return (
        <Img
          src={path}
          alt={alt}
          borderRadius={borderRadius}
          style={style}
          width={width}
          animate={animate}
        />
      );
  }
};

// converts a hexadecimal string to an rgb or rgba string
const hexToRgb = (hex: string, opacity = 1) => {
  const is3LetterHex = !/^#[a-f\d]{3}$/i.test(hex);
  const is6LetterHex = !/^#[a-f\d]{6}$/i.test(hex);

  // if string format is incorrect, throw an error
  if (!is3LetterHex && !is6LetterHex) {
    throw new Error(
      `String format is incorrect. Please provide a string in the format "#06B" or "#0369BE"`,
    );
  }

  // expand 3 character form (e.g. "04D") to 6 characters (e.g. "0044DD")
  if (is3LetterHex) {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, (m, r, g, b) => {
      return r + r + g + g + b + b;
    });
  }

  // convert each pair of values into their corresponding rgb number
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  const r = parseInt(result[1], 16);
  const g = parseInt(result[2], 16);
  const b = parseInt(result[3], 16);

  // if provided, add opacity
  if (opacity >= 1) {
    return `rgb(${r}, ${g}, ${b})`;
  } else if (opacity >= 0) {
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }
};

const AspectRatio = styled.div<{ ratio: number }>`
  position: relative;
  width: 100%;

  &:after {
    display: block;
    content: "";
    padding-top: ${(p) => (p.ratio * 100).toFixed(4)}%;
  }
`;

interface BorderImgProps {
  borderPosition?: "left" | "right";
  borderColor?: string;
  borderOpacity?: number;
  width?: string;
}

const BorderImg = styled.img<BorderImgProps>`
  box-shadow: ${(p) => (p.borderPosition === "left" ? "-1rem" : "1rem")} 1rem 0 0
    ${(p) => hexToRgb(p.borderColor || themeColor.S150, p.borderOpacity)};
  border-radius: 3%;
  object-fit: cover;
  margin: 5rem;
  ${(p) => (p.width ? `width: ${p.width}` : "")};

  ::-moz-selection {
    background: ${themeColor.P20};
  }
  ::selection {
    background: ${themeColor.P20};
  }
`;

const Img = styled.img<{
  withRatio?: boolean;
  borderRadius?: string;
  width?: string;
  animate?: boolean;
}>`
  ${(p) =>
    p.withRatio
      ? `
  position: absolute;
  top: 0;
  left: 0;
  `
      : ""}

  ${(p) => (p.borderRadius ? `border-radius: ${p.borderRadius};` : "")}
  width: ${(p) => p.width || "100%"};
  height: 100%;
  object-fit: cover;
  display: block;

  ::-moz-selection {
    background: ${themeColor.P20};
  }
  ::selection {
    background: ${themeColor.P20};
  }

  ${({ animate }) =>
    animate &&
    `
  transition: transform 1s cubic-bezier(.3,0,0,1) 0s;
  transform: scale(1);
  &:hover {
    transition: transform 10s cubic-bezier(.3,0,0,1) 0s;
    transform: scale(1.1);
  }
  `}
`;

const Picture = styled.picture`
  width: 100%;
  height: 100%;

  img:not(.loaded) {
    filter: blur(0.4rem);
  }
`;
