import { DynamicRoutes, ExternalRoutes, StaticRoutes } from "@routes";
import { YuLifeLocale } from "hooks";

// TODO update US solutions links once pages are created

const data: Data = {
  uk: {
    products: [
      {
        key: "menu-uk-products-health-insurance",
        title: "Health Insurance",
        desc: "Provide your employees with access to medical care when they need it most.",
        href: StaticRoutes.insuranceGroupHealthInsurance,
      },
      {
        key: "menu-uk-products-life-insurance",
        title: "Life Insurance",
        desc: "Plan for the worst, freeing employees to live their best lives.",
        href: StaticRoutes.insuranceGroupLifeInsurance,
      },
      {
        key: "menu-uk-products-income-protection",
        title: "Income Protection",
        desc: "Look after your employees when long-term illness or injury gets in the way.",
        href: StaticRoutes.insuranceGroupIncomeProtection,
      },
      {
        key: "menu-uk-products-dental-insurance",
        title: "Dental Insurance",
        desc: "Another reason to smile: take control of the cost of oral health.",
        href: StaticRoutes.insuranceGroupDentalInsurance,
      },
    ],
    features: [
      {
        key: "menu-uk-features-employee-engagement",
        title: "Employee Engagement",
        href: StaticRoutes.featuresEmployeeEngagement,
      },
      {
        key: "menu-uk-features-incentives-rewards",
        title: "Incentives & Rewards",
        href: StaticRoutes.featuresRewards,
      },
      {
        key: "menu-uk-features-mental-health",
        title: "Mental Health (EAP)",
        href: StaticRoutes.featuresEmployeeAssistanceProgramme,
      },
      {
        key: "menu-uk-features-virtual-gp",
        title: "Virtual GP",
        href: StaticRoutes.featuresVirtualGpServices,
      },
      {
        key: "menu-uk-features-social-impact",
        title: "Social Impact (ESG)",
        href: StaticRoutes.featuresSocialImpactBenefits,
      },
      {
        key: "menu-uk-features-yu-store",
        title: "YuStore",
        href: StaticRoutes.featuresYustore,
      },
      {
        key: "menu-uk-features-reporting",
        title: "Reporting",
        href: StaticRoutes.featuresHrInsights,
      },
      {
        key: "menu-uk-features-wellbeing-hub",
        title: "Wellbeing Hub",
        href: StaticRoutes.featuresWellbeingHub,
      },
    ],
    resources: [
      {
        key: "menu-uk-resources-resource-hub",
        title: "Resource Hub",
        href: StaticRoutes.resourceHub,
      },
      {
        key: "menu-uk-resources-Blog",
        title: "Blogs",
        href: StaticRoutes.blog,
      },
      {
        key: "menu-uk-resources-Case-Studies",
        title: "Case Studies",
        href: StaticRoutes.caseStudies,
      },
      {
        key: "menu-uk-resources-news-and-events",
        title: "News & Events",
        href: StaticRoutes.newsAndEvents,
      },
    ],
    about: [
      {
        key: "menu-uk-about-about-us",
        href: StaticRoutes.aboutUs,
        title: "About Us",
        desc: "We’re on a mission to change insurance for good.",
      },
      {
        key: "menu-uk-about-Careers",
        href: StaticRoutes.careers,
        title: "Careers",
        desc: "We’re always looking for passionate people who share our values!",
      },
      {
        key: "menu-uk-about-Press",
        href: StaticRoutes.press,
        title: "Press",
        desc: "YuLife has everyone buzzing: find out what they’re saying.",
      },
      {
        key: "menu-uk-about-Contact",
        href: StaticRoutes.contactUs,
        title: "Contact",
        desc: "Interested in finding out more? Get in touch with us!",
      },
    ],
    adviser: [
      {
        key: "menu-uk-adviser-become-adviser",
        href: StaticRoutes.adviser,
        title: "Become a YuLife Adviser",
        desc: "Are you new to YuLife? Offer your clients best-in-class insurance and wellbeing benefits. ",
      },
      {
        key: "menu-uk-adviser-explore-adviser",
        href: ExternalRoutes.adviserSupportHub,
        title: "Explore the YuLife Adviser Hub",
        desc: "Already working with us? Find tools to help you seamlessly share YuLife’s story with your clients. ",
      },
    ],
  },
  us: {
    products: [
      {
        key: "menu-us-solutions-individuals",
        title: "For individuals",
        desc: "Boost your wellbeing and earn financial rewards for everyday health activities like walking!",
        href: StaticRoutes.ourSolutionsIndividuals,
      },
      {
        key: "menu-us-solutions-businesses",
        title: "For businesses",
        desc: "Protect your employees with insurance that inspires and rewards your people for living well.",
        href: StaticRoutes.ourSolutionsBusinesses,
      },
      {
        key: "menu-us-solutions-insurance-carriers",
        title: "For insurance carriers",
        desc: "Create a market-leading proposition that drives growth and creates healthier populations.",
        href: StaticRoutes.ourSolutionsInsuranceCarriers,
      },
      {
        key: "menu-us-solutions-brokers-advisors",
        title: "For brokers and advisers",
        desc: "Offer your clients innovative insurance with proven impact and meaningful value.",
        href: StaticRoutes.ourSolutionsBrokersAndAdvisers,
      },
    ],
    productsExtended: [
      {
        key: "menu-us-solutions-group-protection",
        title: "Group protection",
        desc: "Turn traditional insurance and employee benefits into a life-enhancing experience.",
        href: StaticRoutes.insuranceGroupInsurance,
      },
      {
        key: "menu-us-solutions-voluntary-insurance",
        title: "Voluntary insurance",
        desc: "Offer your clients additional protection and wellbeing that rewards them from day one.",
        href: StaticRoutes.insuranceVoluntaryInsurance,
      },
      {
        key: "menu-us-solutions-app-only",
        title: "App only",
        desc: "Already have group risk insurance? You can get all the benefits of YuLife without the insurance.",
        href: StaticRoutes.insuranceAppOnly,
      },
    ],
    features: [
      {
        key: "menu-us-features-Employee-Engagement",
        title: "Employee Engagement",
        href: StaticRoutes.featuresEmployeeEngagement,
      },
      {
        key: "menu-us-features-Incentives-Rewards",
        title: "Incentives & Rewards",
        href: StaticRoutes.featuresRewards,
      },
      {
        key: "menu-us-features-Social-Impact",
        title: "Social Impact (ESG)",
        href: StaticRoutes.featuresSocialImpactBenefits,
      },
      {
        key: "menu-us-features-Wellbeing-Hub",
        title: "Wellbeing Hub",
        href: StaticRoutes.featuresWellbeingHub,
      },
      {
        key: "menu-us-features-reporting",
        title: "Reporting",
        href: StaticRoutes.featuresHrInsights,
      },
    ],
    resources: [
      {
        key: "menu-us-resources-resource-hub",
        title: "Resource Hub",
        href: StaticRoutes.resourceHub,
      },
      {
        key: "menu-us-resources-Blog",
        title: "Blogs",
        href: StaticRoutes.blog,
      },
      {
        key: "menu-us-resources-Case-Studies",
        title: "Case Studies",
        href: StaticRoutes.caseStudies,
      },
      {
        key: "menu-us-resources-news-and-events",
        title: "News & Events",
        href: StaticRoutes.newsAndEvents,
      },
    ],
    about: [
      {
        key: "menu-us-about-about-us",
        href: StaticRoutes.aboutUs,
        title: "About Us",
        desc: "We’re on a mission to change insurance for good.",
      },
      {
        key: "menu-us-about-Careers",
        href: StaticRoutes.careers,
        title: "Careers",
        desc: "We’re always looking for passionate people who share our values!",
      },
      {
        key: "menu-us-about-Press",
        href: StaticRoutes.press,
        title: "Press",
        desc: "YuLife has everyone buzzing: find out what they’re saying.",
      },
      {
        key: "menu-us-about-Contact",
        href: StaticRoutes.contactUs,
        title: "Contact",
        desc: "Interested in finding out more? Get in touch with us!",
      },
    ],
  },
  za: {
    products: [
      {
        key: "menu-za-products-life-cover",
        title: "Life Cover",
        href: StaticRoutes.insuranceGroupLifeInsurance,
      },
      {
        key: "menu-za-products-income-protection",
        title: "Income Protection",
        href: StaticRoutes.insuranceGroupIncomeProtection,
      },
      {
        key: "menu-za-products-funeral-cover",
        title: "Funeral Cover",
        href: StaticRoutes.insuranceFuneralCover,
      },
      {
        key: "menu-za-products-lump-sum-disability",
        title: "Lump Sum Disability",
        href: StaticRoutes.insuranceLumpSumDisability,
      },
      {
        key: "menu-za-products-critical-illness",
        title: "Critical Illness",
        href: StaticRoutes.insuranceGroupCriticalIllness,
      },
      {
        key: "menu-za-products-education-benefit",
        title: "Education Benefit",
        href: StaticRoutes.insuranceEducationBenefit,
      },
    ],
    features: [
      {
        key: "menu-za-features-incentives-rewards",
        title: "Incentives & Rewards",
        href: StaticRoutes.featuresRewards,
      },
      {
        key: "menu-za-features-mental-health",
        title: "Mental Health (EAP)",
        href: StaticRoutes.featuresEmployeeAssistanceProgramme,
      },
      {
        key: "menu-za-features-virtual-gp",
        title: "Virtual GP",
        href: StaticRoutes.featuresVirtualGpServices,
      },
      {
        key: "menu-za-features-wellbeing-hub",
        title: "Wellbeing Hub",
        href: StaticRoutes.featuresWellbeingHub,
      },
      {
        key: "menu-za-features-social-impact",
        title: "Social Impact (ESG)",
        href: StaticRoutes.featuresSocialImpactBenefits,
      },
      {
        key: "menu-za-features-reporting",
        title: "Reporting",
        href: StaticRoutes.featuresHrInsights,
      },
    ],
    resources: [
      {
        key: "menu-za-resources-resource-hub",
        title: "Resource Hub",
        href: StaticRoutes.resourceHub,
      },
      {
        key: "menu-za-resources-Blog",
        title: "Blogs",
        href: StaticRoutes.blog,
      },
      {
        key: "menu-za-resources-Case-Studies",
        title: "Case Studies",
        href: StaticRoutes.caseStudies,
      },
      {
        key: "menu-za-resources-news-and-events",
        title: "News & Events",
        href: StaticRoutes.newsAndEvents,
      },
      {
        key: "menu-za-resources-claim-forms",
        title: "Claim Forms",
        href: StaticRoutes.groupInsuranceForms,
      },
    ],
    about: [
      {
        key: "menu-za-about-about-us",
        href: StaticRoutes.aboutUs,
        title: "About Us",
        desc: "We’re on a mission to change insurance for good.",
      },
      {
        key: "menu-za-about-Careers",
        href: StaticRoutes.careers,
        title: "Careers",
        desc: "We’re always looking for passionate people who share our values!",
      },
      {
        key: "menu-za-about-Press",
        href: StaticRoutes.press,
        title: "Press",
        desc: "YuLife has everyone buzzing: find out what they’re saying.",
      },
      {
        key: "menu-za-about-Contact",
        href: StaticRoutes.contactUs,
        title: "Contact",
        desc: "Interested in finding out more? Get in touch with us!",
      },
    ],
  },
  jp: {
    products: [],
    features: [
      {
        key: "menu-jp-features-incentives-rewards",
        title: "リワード制度",
        href: StaticRoutes.featuresRewards,
      },
      {
        key: "menu-jp-app-only",
        title: "YuLifeアプリ",
        href: StaticRoutes.featuresEmployeeApp,
      },
    ],
    resources: [
      {
        key: "menu-jp-resources-Blog",
        title: "ブログ",
        href: StaticRoutes.blog,
      },
      {
        key: "menu-jp-resources-Case-Studies",
        title: "他社成功事例",
        href: StaticRoutes.caseStudies,
      },
      {
        key: "menu-jp-resources-news-and-events",
        title: "ニュース・イベント",
        href: StaticRoutes.newsAndEvents,
      },
    ],
    about: [],
  },
};

type Data = Record<
  YuLifeLocale,
  {
    products: Product[];
    productsExtended?: Product[];
    features: Feature[];
    resources: Resource[];
    about: About[];
    adviser?: Adviser[]
  }
>;

interface Product {
  key: string;
  title: string;
  desc?: string;
  href: string;
  isLive?: boolean;
}

interface About {
  key: string;
  title: string;
  desc: string;
  href: string;
  isLive?: boolean;
}

interface Feature {
  key: string;
  title: string;
  href: string;
  isLive?: boolean;
}

interface Resource {
  key: string;
  title: string;
  href: string;
  isLive?: boolean;
}

interface Adviser {
  key: string;
  title: string;
  desc: string;
  href: string;
  isLive?: boolean;
}

export default data;
