export const push = {
  transitionStyles: {
    entering: {
      opacity: 1,
      height: "auto",
      transform: `translateX(0)`,
      transitionDuration: `160ms`,
    },
    entered: {
      opacity: 1,
      height: "auto",
      transform: `translateX(0)`,
      transitionDuration: `160ms`,
    },
    exiting: {
      opacity: 0,
      height: 0,
      transform: `translateX(5rem)`,
      transitionDuration: `0ms`,
    },
    exited: {
      opacity: 0,
      height: 0,
      transform: `translateX(5rem)`,
      transitionDuration: `0ms`,
    },
  },
};
