import styled, { css, keyframes } from "styled-components";
import { mobile } from "components/templates/Breakpoints";
import { color as themeColor, fadeOut } from "@styles";

export const ModalContainer = styled.div`
  display: flex;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #5c5757;
  position: absolute;
  top: 1rem;
  right: 0.625rem;
`;

const FadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(3rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const ModalDialog = styled.dialog<{
  isVisible?: boolean;
  background?: string;
  maxWidth?: string;
  maxHeight?: string;
}>`
  background: ${({ background }) => background || themeColor.B220};
  position: absolute;
  border: none;
  width: 100%;
  max-width: ${(p) => p.maxWidth || "46rem"};
  max-height: ${(p) => p.maxHeight || "100vh"};
  transition:
    transform 500ms,
    opacity 500ms;
  transform: ${(p) => (p.isVisible ? "translateY(0rem)" : "translateY(2rem)")};
  display: ${(p) => (p.isVisible ? "flex" : "none")};
  flex-direction: column;
  opacity: ${(p) => (p.isVisible ? "1" : "0")};
  transition: 300ms;
  padding: 0;
  border-radius: 8px;
  overflow: hidden;
  animation: ${FadeInUp} 0.1s normal linear;

  ${(p) =>
    !p.isVisible &&
    css`
      animation: ${fadeOut} 0.1s normal forwards;
    `}

  ${mobile} {
    width: auto;
    margin: 0 0.5rem;
  }
`;

export const ModalOverlay = styled.div<{ isVisible?: boolean }>`
  opacity: ${(p) => (p.isVisible ? "1" : "0")};
  transition: 150ms;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99;
  display: ${(p) => (p.isVisible ? "flex" : "none")};
  justify-content: center;
  align-items: center;
`;
