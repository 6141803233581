export const AsciiYugi = `
  ...............LOVEB.....EING...........
  ...............YULOVEB.EINGYUL..........
  ...OVEBEI.......NGYULOVEBEING...........
  ..YULOVEBEINGY...ULOVEBEINGY............
  ..ULOVEBEINGYULOVEBEINGYUL..............
  ...OVEB.....EINGYULOVEBEINGY............
  ...ULOV......EBEINGYULOVEBEINGYU........
  ....LOVEB.............EI.NGYULOVEBE.....
  .....INGYULOVE.....BEINGYU....LOVEBEI...
  ......NGYULOVEB....EINGYUL.......OVEBEI.
  ...........NGY.....................ULOVE
  ..........BEIN......................GYUL
  ..........OVEB......................EING
  ..........YULO....................VEBEI.
  .........NGYU...LOVEBEIN.......GYULOVE..
  .........BEIN...GYULOVEBEINGYULOVEBEI...
  ........NGYULO.....VEBEINGYULOVEBE......
  ........INGYUL.......OVEB...............
  .......EINGYUL.....OVEBE................
  .......INGYUL......OVEBE................
  ......INGY.......ULOVEBE................
  ......INGY......ULOVEBE.................
  ................INGYULO.................
  ................VEBEIN..................
  `;
