import { YuLifeLocale } from "hooks";
import Image from "next/image";
import { ReactElement } from "react";
import { CURRENT_YEAR, YULIFE_DATA } from "utils/_constants";
import { FlexContainer, StyledLink, StyledP } from "./styles";
import Link from "next/link";
import { P1 } from "components/atoms/Paragraph";
import styled from "styled-components";

type Data = Record<
  YuLifeLocale,
  {
    legalDisclaimer: ReactElement;
    bupaDisclaimer?: ReactElement;
    privateHealthDisclaimer?: ReactElement;
    metLifeGipDisclaimer?: ReactElement;
  }
>;

const Bold = styled.span`
  font-weight: 700;
`;

const data: Data = {
  uk: {
    legalDisclaimer: (
      <P1>
        {CURRENT_YEAR} © Yu Life Ltd. Yu Life Ltd is authorised and regulated by the Financial
        Conduct Authority. Firm reference No. 783352. Yu Life Ltd is a company registered in
        England, company no. 10308260, registered office: 12 Mallow Street, London, EC1Y 8RQ.
      </P1>
    ),
    bupaDisclaimer: (
      <>
        <P1 pt="0">
          1{" "}
          <Link href="https://www.cdc.gov/chronicdisease/about/prevent/">
            https://www.cdc.gov/chronicdisease/about/prevent/
          </Link>
        </P1>
        <P1>
          2{" "}
          <Link href="https://www.bupa.co.uk/~/media/Files/MMS/bins-01113">
            https://www.bupa.co.uk/~/media/Files/MMS/bins-01113
          </Link>
        </P1>
        <P1>
          3 As of March 2024, this comparison to other products in the market is based on Bupa’s and
          Defaqto’s interpretation of the differences between the Bupa’s SME Select health insurance
          and other health insurance products offering mental health cover. The comparison excludes
          any special offers or promotions which may temporarily alter the cover offered. Cover
          comparison information is for personal use and guidance only and does not constitute any
          contractual representation, warranty or obligation by either Bupa or Defaqto about the
          completeness, accuracy, reliability, suitability or availability of the comparison.
          Neither party accepts any liability for errors, omissions, direct or consequential loss in
          relation to this comparison. See bupa.co.uk/compare
        </P1>
        <P1>
          4 With Bupa cancer cover there are no time limits. All eligible treatment costs and
          evidence-based breakthrough cancer drugs and treatments that your scheme covers are paid
          in full for as long as your employee has Bupa health cover. Employees will need to check
          their certificate or guide to see which specific list of advanced therapies has been
          selected as it may not cover all advanced therapies. Your employees will need to use a
          hospital or clinic from the Bupa network that applies to their cover and a Bupa recognised
          consultant who will charge within our rates (a fee-assured consultant).
        </P1>
        <P1>5 {YULIFE_DATA}</P1>
        <P1>
          6 A commissioned Forrester Consulting TEI Study. Results are for a composite organisation
          and represent the incremental value of YuLife's health and wellbeing engagement.
        </P1>
        <P1>
          7 Available on Bupa Select Health Insurance through YuLife only. Not every product or
          service will be available to claim each policy year. Employees unlock additional products
          and services through engagement with YuLife over time. This is in addition to normal
          YuCoin rewards for activities including mindfulness, walking, and cycling. The specific
          products and services may be subject to change, and are provided by YuLife partners
          outside of the scope of Bupa Select Health Insurance.
        </P1>
        <P1>
          Bupa health insurance is provided by Bupa Insurance Limited. Registered in England and
          Wales with registration number 3956433. Bupa Insurance Limited is authorised by the
          Prudential Regulation Authority and regulated by the Financial Conduct Authority and the
          Prudential Regulation Authority. Arranged and administered by Bupa Insurance Services
          Limited, which is authorised and regulated by the Financial Conduct Authority. Registered
          in England and Wales with registration number 3829851. Registered office: 1 Angel Court,
          London EC2R 7HJ
        </P1>
      </>
    ),
    privateHealthDisclaimer: (
      <>
        <P1>
          ¹
          <Link href="https://www.cdc.gov/chronicdisease/about/prevent/">
            {" "}
            https://www.cdc.gov/chronicdisease/about/prevent/
          </Link>
        </P1>
        <P1 pt="0">
          ²
          <Link href="https://www.bupa.co.uk/~/media/Files/MMS/bins-01113">
            {" "}
            https://www.bupa.co.uk/~/media/Files/MMS/bins-01113
          </Link>
        </P1>
        <P1>
          Bupa health insurance is provided by Bupa Insurance Limited. Registered in England and
          Wales No. 3956433. Bupa Insurance Limited is authorised by the Prudential Regulation
          Authority and regulated by the Financial Conduct Authority and the Prudential Regulation
          Authority. Arranged and administered by Bupa Insurance Services Limited, which is
          authorised and regulated by the Financial Conduct Authority. Registered in England and
          Wales No. 3829851. Registered office: 1 Angel Court, London EC2R 7HJ
        </P1>
      </>
    ),
    metLifeGipDisclaimer: (
      <>
        <P1 pt="0">
          <Bold>1</Bold> 2024 data from users who have downloaded the YuLife app in the last year
          and have been active in the last 30 days.
        </P1>
        <P1>
          <Bold>2</Bold>{" "}
          <Link href="https://www.metlife.co.uk/intermediary/employee-benefits/knowledge-expertise/metlife-academy/risk/claims-experience/">
            https://www.metlife.co.uk/intermediary/employee-benefits/knowledge-expertise/metlife-academy/risk/claims-experience/
          </Link>
        </P1>
        <P1>
          <Bold>3</Bold> GRID Claims Survey 2024, <Link href="https://www.grouprisk.org.uk">grouprisk.org.uk</Link>
        </P1>
        <P1>
          <Bold>4</Bold> GP24 is provided by HealthHero Solutions Limited, a company incorporated in
          England with company number 03766413 whose registered address is at 10 Upper Berkeley
          Street, London, W1H 7PE.
        </P1>
        <P1>
          <Bold>5</Bold> Health Claims Bureau Registered in England No: 2820780. Registered Office:
          Weirbank, Monkey Island Lane, Berkshire SL6 2ED6 CIPD, Health and wellbeing at work
          report, 2023.
        </P1>
        <P1>
          <Bold>6</Bold> Data from CIPD’s health and wellbeing at work 2023 survey, supported by
          Simplyhealth (
          <Link href="https://www.cipd.org/uk/about/press-releases/workplace-absences-highest-over-decade-new-report/">
            https://www.cipd.org/uk/about/press-releases/workplace-absences-highest-over-decade-new-report/)
          </Link>
          .
        </P1>
        <P1>
          <Bold>7</Bold> YuLife user survey, 2022.
        </P1>
        <P1>
          <Bold>8</Bold> The Economic Impact of YuLife, Forrester Consulting, November 2022.
        </P1>
        <P1>
          <Bold>9</Bold> Reward partners are subject to change at any time. Physical rewards require
          a shipping cost paid by the employee to be redeemed.
        </P1>
        <P1>
          Products and services are offered by MetLife Europe d.a.c. which is an affiliate of
          MetLife, Inc. and operates under the “MetLife” brand. MetLife Europe d.a.c. is a private
          company limited by shares, registered in Ireland under company number 415123. Registered
          office at 20 on Hatch, Lower Hatch Street, Dublin 2, Ireland. UK branch office at Invicta
          House, Trafalgar Place, Brighton BN1 4FR. Branch establishment number: BR008866. MetLife
          Europe d.a.c. (trading as MetLife) is authorised and regulated by Central Bank of Ireland.
          Authorised by the Prudential Regulation Authority. Subject to regulation by the Financial
          Conduct Authority and limited regulation by the Prudential Regulation Authority. Details
          about the extent of our regulation by the Prudential Regulation Authority are available
          from us on request.
        </P1>
      </>
    ),
  },

  us: {
    legalDisclaimer: (
      <>
        <P1 pt="0">
          {CURRENT_YEAR} © YuLife Insurance Agency, Inc. YuLife Insurance Agency, Inc. is a
          corporation registered in the State of Delaware and located at 3379 Peachtree Road NE
          (Buckhead), Suite 555, Atlanta, GA 30326.
        </P1>
      </>
    ),
  },
  za: {
    legalDisclaimer: (
      <FlexContainer>
        <StyledP>
          YuLife SA Pty Ltd is an authorised financial services provider (FSP No 52478). Products
          are underwritten by Guardrisk Life Limited, an authorised financial services provider (FSP
          No 76) and a licenced life insurer.
        </StyledP>
        <Image
          src="/static/logos/guardrisk-logo.png"
          width={213}
          height={31}
          alt="Guard risk logo"
        />
      </FlexContainer>
    ),
  },
  jp: {
    legalDisclaimer: (
      <>
        <p>YuLife Japan合同会社（事業者届出番号：0100-03-044277）</p>
        <p>
          登録事業所：C/O A&Sフィナンシャルアドバイザリー株式会社　住所：〒100-0011
          東京都千代田区内幸町2-2-2 富国生命ビル 13F
        </p>
      </>
    ),
  },
};

export default data;
