import React, { forwardRef } from "react";
import Image from "next/legacy/image";
import styled from "styled-components";
import { color as themeColor, fonts } from "@styles";
import { mobile } from "components/templates/Breakpoints";

type CountryLinkProps = {
  country: string;
  link?: string;
  flag: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
};

// eslint-disable-next-line react/display-name
export const CountryLink = forwardRef(
  (props: CountryLinkProps, ref: React.ForwardedRef<HTMLAnchorElement>) => (
    <A href={props.link} ref={ref} onClick={props.onClick}>
      <Image
        src={`/static/icons/flags/${props.flag}.svg`}
        alt={props.country}
        height={16}
        width={16}
      />
      <Description>{props.country}</Description>
      <ArrowContainer>
        <svg xmlns="http://www.w3.org/2000/svg" width="48" height="24" fill="none">
          <g clipPath="url(#a)">
            <path
              d="M33 7.56c0-.15.06-.29.18-.4a.63.63 0 0 1 .84 0l4.8 4.45c.24.21.24.57 0 .78l-4.8 4.45a.63.63 0 0 1-.47.16h-.05a.5.5 0 0 1-.5-.5V7.56Z"
              fill="#5C5757"
            />
          </g>
          <defs>
            <clipPath id="a">
              <path fill="#fff" transform="rotate(90 24 24)" d="M0 0h24v48H0z" />
            </clipPath>
          </defs>
        </svg>
      </ArrowContainer>
    </A>
  ),
);

export const LanguageSwitcher = ({ altLangs, path, onClick }) => (
  <ul>
    {Object.entries(altLangs).map(([country, altLang]: [string, CountryLinkProps]) => {

      return (
        <CountryItem key={country}>
          <CountryLink
            country={altLang.country}
            link={`${country === "uk" ? "" : `/${country}`}${path}`}
            flag={altLang.flag}
            onClick={(e) => onClick(e, country)}
          />
        </CountryItem>
      );
    })}
  </ul>
);

const A = styled.a`
  font-family: ${fonts.body};
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  display: flex;
  align-items: center;
  letter-spacing: 1.2px;
  color: ${themeColor.B1100};
  text-indent: 0.5rem;

  svg {
    transform: translateX(-2rem);
    transition: 250ms ease-in-out;
  }

  &:hover {
    color: ${themeColor.P100};

    svg {
      transform: translateX(-1.5rem);
    }

    path {
      fill: ${themeColor.P100};
    }
  }
`;

const Description = styled.span`
  padding-right: 0.25rem;
`;

export const CountryItem = styled.li`
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 0;
  margin: 1rem 0;
  cursor: pointer;

  ${mobile} {
    margin: 0 auto;
    padding-bottom: 0.5rem;
  }
`;

const ArrowContainer = styled.div`
  transform: translateY(1px);
`;
