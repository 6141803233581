import { FC } from "react";
import { Transition } from "react-transition-group";
import { push } from "./PushLeft.styles";

const PushLeft: FC<React.PropsWithChildren<{ hasEntered?: boolean; transitionDelay?: number }>> = ({
  hasEntered,
  transitionDelay = 0,
  children,
}) => {
  return (
    // @ts-ignore: <Transition> error with @types/react 18.0.3
    <Transition in={hasEntered} timeout={160}>
      {(state) => (
        <div
          style={{
            transitionDelay: `${hasEntered ? transitionDelay : 0}ms`,
            pointerEvents: `${hasEntered ? "all" : "none"}`,
            ...push.transitionStyles[state],
          }}
        >
          {children}
        </div>
      )}
    </Transition>
  );
};

export default PushLeft;
