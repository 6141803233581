import { color as themeColor } from "styles";

export const Cross = ({ color, className, ...props }: { color?: string; className?: string }) => (
  <svg
    className={className}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.84024 2.84019C3.07166 2.60877 3.44687 2.60877 3.67829 2.84019L8 7.16191L12.3217 2.84019C12.5531 2.60877 12.9283 2.60877 13.1598 2.84019C13.3912 3.07161 13.3912 3.44682 13.1598 3.67824L8.83806 7.99996L13.1598 12.3217C13.3912 12.5531 13.3912 12.9283 13.1598 13.1597C12.9283 13.3911 12.5531 13.3911 12.3217 13.1597L8 8.83801L3.67829 13.1597C3.44687 13.3911 3.07166 13.3911 2.84024 13.1597C2.60882 12.9283 2.60882 12.5531 2.84024 12.3217L7.16195 7.99996L2.84024 3.67824C2.60882 3.44682 2.60882 3.07161 2.84024 2.84019Z"
      fill={color || themeColor.B1100}
    />
  </svg>
);
