import { color as YU_COLOURS } from "@styles";
import { Link } from "@components/atoms";
import { FC } from "react";
import styled from "styled-components";
import { color, fonts } from "../../styles";
import { P1 } from "../atoms/Paragraph";

interface MegaMenuCalloutProps {
  pillText: string;
  desc: string;
  CTADesc: string;
  link: string;
}

export const MegaMenuCallout: FC<MegaMenuCalloutProps> = ({ pillText, desc, CTADesc, link }) => (
  <StyledLink href={link}>
    <Container>
      <CenterAligned>
        <Pill>
          <PillText>{pillText}</PillText>
        </Pill>
        <StyledP1>{desc}</StyledP1>
      </CenterAligned>

      <CTA>
        <CTAText>{CTADesc}</CTAText>
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="18" fill="none">
          <path
            d="m1 17 8-8-8-8"
            stroke={color.P100}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </CTA>
    </Container>
  </StyledLink>
);

const StyledLink = styled(Link)`
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;
  gap: 1rem;
  position: relative;
  width: 100%;
  height: 5rem;
  background: ${YU_COLOURS.B210};
  border-bottom-left-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  border-top: 0.063rem solid ${YU_COLOURS.B230};

  svg {
    transition: 200ms;
  }

  &:hover {
    background: #fce7f1;

    svg {
      transform: translateX(0.5rem);
      path {
        stroke: ${color.P100};
      }
    }
  }
`;

const Pill = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0.25rem 1rem;
  gap: 0.5rem;
  width: 3.688rem;
  height: 2rem;
  background: ${color.S310};
  border: 0.063rem solid ${color.S3100};
  border-radius: 0.5rem;
`;

const PillText = styled.div`
  font-family: ${fonts.body};
  font-style: normal;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1.5rem;
  letter-spacing: 0.05rem;
  color: ${color.S3100};
`;

const StyledP1 = styled(P1)`
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0.063rem 0 0;
`;

const CTA = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const CTAText = styled(P1)`
  padding-top: 0.063rem;
  height: 1.5rem;
  font-family: ${fonts.body};
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  letter-spacing: 0.075rem;
  text-decoration-line: underline;
  color: ${color.P100};
  padding-right: 1rem;
`;

const CenterAligned = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;
