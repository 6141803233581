import React, { MouseEvent, useCallback, useRef, useEffect } from "react";
import type { FC, ReactNode } from "react";
import { createPortal } from "react-dom";
import { ModalDialog, ModalOverlay } from "../styles";
import { useTrapFocus, useEventListener, useClientOnly } from "hooks";
import { useScrollBlock } from "hooks/useScrollBlock";
import { BaseModalProps } from "../types";

export const MODAL_ID = "modal";

export const BaseModal: FC<React.PropsWithChildren<BaseModalProps>> = ({
  closeOnClickOutside,
  showModal,
  closeModal,
  background,
  maxHeight = "37.25rem",
  maxWidth = "46rem",
  children,
}) => {
  const overlayRef = useRef<HTMLDivElement>(null);
  const { hasMounted } = useClientOnly();

  const trapRef = useTrapFocus<HTMLDialogElement>({
    includeContainer: true,
    returnFocus: true,
    updateNodes: false,
    isActive: showModal,
  });

  const handleOverlayClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      if (e.target === overlayRef.current && closeOnClickOutside) {
        closeModal();
      }
    },
    [closeModal, closeOnClickOutside],
  );

  useEventListener({
    type: "keydown",
    listener: (e) => {
      if ((e as KeyboardEvent).key === "Escape") {
        closeModal();
      }
    },
  });

  const [blockScroll, allowScroll] = useScrollBlock();

  useEffect(() => {
    if (showModal) {
      blockScroll();
    } else {
      allowScroll();
    }

    return () => {
      allowScroll();
    };
  }, [showModal, blockScroll, allowScroll]);

  if (!hasMounted) {
    return null;
  }

  return createPortal(
    <ModalOverlay
      ref={overlayRef}
      isVisible={showModal}
      onClick={handleOverlayClick}
      aria-hidden={!showModal}
    >
      <ModalDialog
        isVisible={showModal}
        open={showModal}
        hidden={!showModal}
        aria-hidden={!showModal}
        id={MODAL_ID}
        aria-label={MODAL_ID}
        aria-modal="true"
        role="dialog"
        background={background}
        ref={trapRef}
        maxWidth={maxWidth}
        maxHeight={maxHeight}
      >
        {children}
      </ModalDialog>
    </ModalOverlay>,
    document.querySelector("body"),
  ) as ReactNode;
};
