import { FC } from "react";
import { H2 } from "../atoms/Heading";
import styled from "styled-components";
import { color, fonts } from "../../styles";
import { P1 } from "components/atoms/Paragraph";
import { convertNewlinesToBreaks } from "utils/string";

interface MegaMenuLargeBlockProps {
  title: string;
  subheading?: string;
  desc: string;
  link?: string;
  mb?: string;
}

export const MegaMenuLargeBlock: FC<MegaMenuLargeBlockProps> = ({
  title,
  subheading,
  desc,
  link,
  mb,
}) => (
  <Container mb={mb} link={link}>
    {subheading && (
      <Subheading>
        <Sub>{subheading.toLocaleUpperCase()}</Sub>
      </Subheading>
    )}
    <Heading>
      <StyledH2>{convertNewlinesToBreaks(title)}</StyledH2>
      {link && (
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="18" fill="none">
          <path
            d="m1 17 8-8-8-8"
            stroke={color.B1100}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </Heading>
    <div>
      <StyledP1>{convertNewlinesToBreaks(desc)}</StyledP1>
    </div>
    {link && <Link>{link}</Link>}
  </Container>
);

const Container = styled.div<{ link: string; mb: string }>`
  margin-bottom: ${(p) => p.mb};
  cursor: ${(p) => (p.link ? "pointer" : "auto")};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
  width: 25.5rem;
  border-radius: 0.5rem;
  transition: 200ms;
  svg {
    transition: 200ms;
  }

  &:hover {
    background: ${(p) => (p.link ? color.P10 : "none")};

    svg {
      transform: translateX(0.5rem);
      path {
        stroke: ${color.P100};
      }
    }
  }
`;

const Heading = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  padding-bottom: 1rem;
  width: 21.875rem;
`;

const Subheading = styled.div`
  padding-botton: 0.25rem;
`;

const StyledP1 = styled(P1)`
  font-family: ${fonts.body};
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0;
  width: 100%;
`;

const Sub = styled(P1)`
  font-family: ${fonts.body};
  font-size: 0.875rem;
  line-height: 1.5rem;
  padding: 0;
  color: ${color.B2100};

  ${Container}:hover & {
    color: ${color.B1100};
  }
`;

const StyledH2 = styled(H2)`
  font-family: ${fonts.heading};
  font-style: normal;
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 2.625rem;
`;

const Link = styled(P1)`
  text-decoration-line: underline;
  color: ${color.P100};
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 0;
  padding-top: 1rem;
`;
