import { useClientOnly } from "hooks";
import { useState, useEffect, useCallback } from "react";

export type GTMPreferences = {
  ad_storage: "denied" | "granted";
  analytics_storage: "denied" | "granted";
  functionality_storage: "granted";
  personalization_storage: "denied" | "granted";
  security_storage: "denied" | "granted";
};

export const useCookiePreferences = () => {
  const { hasMounted } = useClientOnly();

  const [cookiePreferences, setCookiePreferences] = useState<GTMPreferences>({
    ad_storage: "granted",
    analytics_storage: "granted",
    functionality_storage: "granted",
    personalization_storage: "granted",
    security_storage: "granted",
  });

  const updateCookiePreferences = useCallback(
    (preferences: Omit<GTMPreferences, "functionality_storage">) => {
      function gtag(...args) {
        if (hasMounted) {
          window.dataLayer.push(arguments);
        }
      }

      try {
        const updatedPreferences: GTMPreferences = {
          ...preferences,
          functionality_storage: "granted",
        };
        localStorage.setItem("gdpr", JSON.stringify(updatedPreferences));
        gtag("consent", "update", updatedPreferences);
        setCookiePreferences(updatedPreferences);
      } catch (e) {}
    },
    [hasMounted],
  );

  useEffect(() => {
    const prefs = localStorage.getItem("gdpr")
      ? JSON.parse(localStorage.getItem("gdpr"))
      : {
          ad_storage: "granted",
          analytics_storage: "granted",
          functionality_storage: "granted",
          personalization_storage: "granted",
          security_storage: "granted",
        };

    setCookiePreferences(prefs);
  }, []);

  return {
    cookiePreferences,
    updateCookiePreferences,
  };
};
