import styled from "styled-components";
import { color, fonts } from "@styles";
import { Heading } from "components/atoms/Heading";
import { Button } from "components/atoms/Button";
import { mobile } from "components/templates/Breakpoints";

export const StyledHeading = styled(Heading)`
  font-family: ${fonts.body};
  font-size: 1.5rem;
  color: ${color.B1100};
  font-weight: 700;
  margin-left: 2rem;
`;

export const ModalHeader = styled.div`
  display: flex;
`;

export const ModalImage = styled.div`
  align-self: center;
`;

export const ModalHeaderContent = styled.div<{ padding?: string; spacing: string }>`
  align-self: center;
  padding: 2rem 2rem 2rem 0;

  & > *:not(:first-child) {
    margin-top: ${(props) => props.spacing};
  }
`;

export const ModalButton = styled(Button)`
  height: 3.25rem;
  margin: 0;
  ${mobile} {
    min-width: 100%;
  }
`;

export const ModalContent = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
`;

export const ModalButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;

  ${mobile} {
    flex-direction: column;
  }
`;
export const ButtonContainerLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  ${mobile} {
    flex-direction: column;
    width: 100%;
    gap: 0;
  }
`;
export const ButtonContainerRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
  ${mobile} {
    flex-direction: column;
    width: 100%;
  }
`;
