import { file } from "@utils";
import Image from "next/legacy/image";

export enum NavIconNames {
  inspiringLife = "Inspiring life",
  caseStudies = "Case studies",
  community = "Community",
  blog = "Blog",
  events = "Events",
  faq = "FAQ",
  customerCare = "Customer care",
  advisors = "Advisors",
  ourStory = "Our story",
  ourTeam = "Our team",
  press = "Press",
  contactUs = "Contact us",
  hrLogin = "HR login",
  employeeLogin = "Employee login",
}

export const NavIcon = ({ name }: { name: NavIconNames }) => {
  switch (name) {
    case NavIconNames.inspiringLife:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none">
          <rect width="40" height="40" rx="20" fill="#F4F0FF" />
          <path
            clipRule="evenodd"
            d="M19.8 17a5.2 5.2 0 10-7.2 7.4l6.2 6.2c.5.5 1.5.5 2 0l6.2-6.1c2-2 2.2-5 .3-7.1a5.2 5.2 0 00-7.5-.3z"
            stroke="#956AFF"
            strokeWidth="2"
          />
          <path
            d="M19.8 11.2V9M25.7 10.1L24 11.2M15.7 11.1l-1.7-1"
            stroke="#956AFF"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case NavIconNames.caseStudies:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none">
          <rect width="40" height="40" rx="20" fill="#E3F7FC" />
          <path
            clipRule="evenodd"
            d="M18.5 15.3c-1-1-2.5-1-3.4.1-.8 1-.7 2.4.2 3.3l2.8 2.7c.2.3.7.3 1 0l2.7-2.7c1-1 1-2.3.2-3.3-1-1-2.5-1-3.5-.1z"
            stroke="#00C0F3"
            strokeWidth="2"
            strokeLinecap="round"
          />
          <path
            d="M30 10.9V31H12.1a2.1 2.1 0 110-4.2h14.3V9H13a3 3 0 00-3 3v17.1"
            stroke="#00C0F3"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case NavIconNames.community:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none">
          <rect width="40" height="40" rx="20" fill="#E4FCF4" />
          <path
            d="M15.7 15.2s-2 .7-3 3.5M24 14.2a4.2 4.2 0 11-8.4 0 4.2 4.2 0 018.4 0zM16 29s2 1 5 .7m-3.6-3.9a4.2 4.2 0 11-8.4 0 4.2 4.2 0 018.4 0zM28 21.8s.3-2.2-1.6-4.5m4.6 8.5a4.2 4.2 0 11-8.4 0 4.2 4.2 0 018.4 0z"
            stroke="#00ED9D"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case NavIconNames.blog:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none">
          <rect width="40" height="40" rx="20" fill="#FFFBE5" />
          <path
            d="M19.4 19l3 7.9m-7.3-12.6l-.2 6 7.7 6.7 1.3-10.3-4-4.1-4.8 1.7zm-1.3-3.5L18.6 9l1.3 3.6-4.8 1.7-1.3-3.5z"
            stroke="#FFD600"
            strokeWidth="1.8"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10 31h20"
            stroke="#FFD600"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case NavIconNames.events:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none">
          <rect width="40" height="40" rx="20" fill="#F4F0FF" />
          <g
            clipPath="url(#clip-events)"
            stroke="#956AFF"
            strokeWidth="2.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M20 15.6l2 3.6 4.7.6-3.4 2.8.8 4-4.1-1.9-4.1 1.9.8-4-3.4-2.8 4.6-.6 2.1-3.6z" />
            <path d="M24.4 9v4.4M15.6 9v4.4m-3.4-2.2h15.6c1.2 0 2.2 1 2.2 2.2v15.4c0 1.2-1 2.2-2.2 2.2H12.2C11 31 10 30 10 28.8V13.4c0-1.2 1-2.2 2.2-2.2z" />
          </g>
          <defs>
            <clipPath id="clip-events">
              <path fill="#fff" transform="translate(8 8)" d="M0 0h24v24H0z" />
            </clipPath>
          </defs>
        </svg>
      );
    case NavIconNames.faq:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none">
          <rect width="40" height="40" rx="20" fill="#E3F7FC" />
          <path
            d="M20 30a10 10 0 100-20 10 10 0 000 20z"
            stroke="#00C0F3"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17 17a3 3 0 016 1c0 2-3 3-3 3M20 24v.5"
            stroke="#00C0F3"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case NavIconNames.customerCare:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none">
          <rect width="40" height="40" rx="20" fill="#F4F0FF" />
          <path
            d="M9 27.2l4-3.7s.6-.5 2.6-.5h7c.3 0 .6.3.6.6v0c0 1.1-1 2-2 2h-3.6"
            stroke="#956AFF"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13 30.5l.7-.7c.9-1 2.1-1.4 3.4-1.4h6.5s0 0 0 0 .5 0 1.6-1l5.1-6.2c.4-.4.4-1.1 0-1.5v0c-.5-.5-1.3-.5-1.7 0l-2.9 2.8"
            stroke="#956AFF"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            clipRule="evenodd"
            d="M19.7 11a3 3 0 00-4.5 0c-1.1 1.4-1 3.3.2 4.5l3.7 3.8c.3.4.9.4 1.2 0l3.7-3.8a3.3 3.3 0 00.2-4.4 3 3 0 00-4.5-.2z"
            stroke="#956AFF"
            strokeWidth="2"
          />
        </svg>
      );
    case NavIconNames.advisors:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none">
          <rect width="40" height="40" rx="20" fill="#E4FCF4" />
          <path
            d="M25.1 12c1.7.4 3.2 1.4 4.3 2.7a7.4 7.4 0 010 9.3 7.8 7.8 0 01-4.3 2.7"
            stroke="#00ED9D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.3 19.8c0-.6-.5-1-1-1a1 1 0 00-1 1c0 .5.4 1 1 1 .5 0 1-.5 1-1zM16.2 19.8c0 .5.4 1 1 1 .5 0 1-.5 1-1 0-.6-.5-1-1-1a1 1 0 00-1 1zM19 19.8c0 .5.5 1 1 1 .6 0 1-.5 1-1 0-.6-.4-1-1-1a1 1 0 00-1 1z"
            fill="#00ED9D"
          />
          <path
            d="M24.4 19.3a7.2 7.2 0 01-3.5 6.2 7.3 7.3 0 01-7 .3L9 27.4l1.6-4.9a7.2 7.2 0 01.3-7 7.3 7.3 0 016.2-3.5h.5a7.3 7.3 0 016.8 6.8v.5z"
            stroke="#00ED9D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case NavIconNames.ourStory:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none">
          <rect width="40" height="40" rx="20" fill="#E4FCF4" />
          <g
            clipPath="url(#clip-ourStory)"
            stroke="#00ED9D"
            strokeWidth="2.2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path
              d="M20 31l-9.4-2.7c-1-.2-1.6-1.1-1.6-2v-8.4c0-1.5 1.4-2.5 2.8-2.1l5 1.4c1.9.6 3.2 2.3 3.2 4.2m0 9.6v-9.6m0 9.6l9.4-2.7c1-.2 1.6-1.1 1.6-2v-8.4c0-1.5-1.4-2.5-2.8-2.1l-5 1.4a4.4 4.4 0 00-3.2 4.2"
              strokeMiterlimit="10"
            />
            <path d="M20 12v-2M25.5 12l1.6-1M14.6 12L13 11" />
          </g>
          <defs>
            <clipPath id="clip-ourStory">
              <path fill="#fff" transform="translate(8 8)" d="M0 0h24v24H0z" />
            </clipPath>
          </defs>
        </svg>
      );
    case NavIconNames.ourTeam:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none">
          <rect width="40" height="40" rx="20" fill="#E3F7FC" />
          <path
            d="M27.3 28.1v-1.7c0-.9-.4-1.8-1-2.4-.7-.6-1.7-1-2.6-1h-7.4c-1 0-1.9.4-2.6 1-.6.6-1 1.5-1 2.4v1.7M20 19.3a3.7 3.7 0 100-7.3 3.7 3.7 0 000 7.3zM31 26.6V25a3 3 0 00-2.2-2.8M25.9 13.5a3 3 0 010 5.6M9 26.6V25a3 3 0 012.2-2.8M14.1 13.5a3 3 0 000 5.6"
            stroke="#00C0F3"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case NavIconNames.press:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none">
          <rect width="40" height="40" rx="20" fill="#F4F0FF" />
          <path
            d="M11.4 30H29a2 2 0 002-2V11a2 2 0 00-2-2H15.7a2 2 0 00-2 2v5m0 0v11.7c0 1.3-1 2.4-2.3 2.4v0C10 30 9 29 9 27.7V16h4.7zM18.6 22h8M18.6 25.7h8"
            stroke="#956AFF"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M24 14h-5v4h5v-4z"
            stroke="#956AFF"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case NavIconNames.contactUs:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none">
          <rect width="40" height="40" rx="20" fill="#FFFBE5" />
          <path
            d="M9 13.1l10.8 8.3c.4.3 1 .3 1.3 0L31 13M10 27.4h20c.6 0 1-.4 1-1V13c0-.6-.4-1-1-1H10a1 1 0 00-1 1v13.4c0 .6.4 1 1 1z"
            stroke="#FFD600"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case NavIconNames.hrLogin:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none">
          <rect width="40" height="40" rx="20" fill="#E3F7FC" />
          <g clipPath="url(#clip-hrLogin)">
            <path
              d="M31 30l-5-5m-.8.3a3 3 0 00-3-2.9h-5.8a3 3 0 00-2.9 3m15.2-7a9.3 9.3 0 11-18.7 0 9.3 9.3 0 0118.7 0zm-6.4-1.8a3 3 0 11-5.9 0 3 3 0 015.9 0z"
              stroke="#00C0F3"
              strokeWidth="2.2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
          <defs>
            <clipPath id="cliphrLogin">
              <path fill="#fff" transform="translate(8 8)" d="M0 0h24v24H0z" />
            </clipPath>
          </defs>
        </svg>
      );
    case NavIconNames.employeeLogin:
      return (
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="none">
          <rect width="40" height="40" rx="20" fill="#E4FCF4" />
          <path
            d="M28 29v-2a4 4 0 00-4-4h-8a4 4 0 00-4 4v2M20 19a4 4 0 100-8 4 4 0 000 8z"
            stroke="#00ED9D"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    default:
      return <Image src={file(`/icon/${name}.svg`)} alt={name} height={64} width={64} />;
  }
};
