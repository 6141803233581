import { MegaMenuLargeBlock } from "components/molecules/MegaMenuLargeBlock";
import { MegaMenuSmallBlock } from "components/molecules/MegaMenuSmallBlock";
import { Link } from "@components/atoms";
import { FC } from "react";
import { StaticRoutes } from "@routes";
import styled from "styled-components";
import { color } from "../../../styles";
import { Column, TextBlock, Asset, RightColumn, Container, ResizableContainer } from "./styles";
import { useLocaleData } from "hooks/useLocaleData";
import data from "./translations";
import { useTranslations } from "next-intl";

export const MegaMenuFeatures: FC = () => {
  const t = useTranslations("shared/mega-menu");
  const { features } = useLocaleData(data);
  return (
    <ResizableContainer>
      <StyledContainer>
        <Column>
          <TextBlock>
            <Link href={StaticRoutes.featuresEmployeeApp}>
              <MegaMenuLargeBlock
                title={t<string>("features.left.title")}
                subheading={t<string>("features.left.subheading")}
                desc={t<string>("features.left.desc")}
                link={t<string>("features.left.link")}
              />
            </Link>
          </TextBlock>
          <Asset
            height={216}
            width={453}
            src={t<string>("features.left.asset.src")}
            alt={t<string>("features.left.asset.alt")}
            style={{ width: "auto" }}
            priority
          />
        </Column>
        <MiddleColumn>
          {features
            .filter((item) => item.isLive === undefined || item.isLive === true)
            .map((feature) => (
              <Link key={feature.key} href={feature.href}>
                <MegaMenuSmallBlock title={feature.title} />
              </Link>
            ))}
        </MiddleColumn>
        <RightColumn>
          <TextBlock>
            <Link href={StaticRoutes.featuresPortal}>
              <MegaMenuLargeBlock
                title={t<string>("features.right.title")}
                subheading={t<string>("features.right.subheading")}
                desc={t<string>("features.right.desc")}
                link={t<string>("features.right.link")}
              />
            </Link>
          </TextBlock>
          <AssetYugi
            height={216}
            width={453}
            src={t<string>("features.right.asset.src")}
            alt={t<string>("features.right.asset.alt")}
            priority
          />
        </RightColumn>
      </StyledContainer>
    </ResizableContainer>
  );
};

const MiddleColumn = styled(Column)`
  padding-top: 2.5rem;
  background: ${color.white};
`;

const AssetYugi = styled(Asset)`
  padding-top: 2.188rem;
  width: 100%;
  height: auto;
`;

const StyledContainer = styled(Container)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;
