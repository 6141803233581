import React, { ChangeEvent, FC } from "react";
import styled from "styled-components";

// A hidden form field that human users cannot see and therefore do not fill out, but spam bots might. We can use this to try to reduce spam submissions.
// We can't use "HoneyPot" in the component or file name, as this can be visible in the class names and might mean spam bots can avoid it.
export const AlternateFormField: FC<{
  name?: string;
  label?: string;
  inputType?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}> = (
  // Using "otherEmail" is a compromise: it looks legit enough to be enticing to spambots but we avoid conflicts with the "email" field (which could be filled in automatically by autofill features, e.g. in Chrome, 1Password, etc.)
  { name = "otherEmail", label = "Other Email", inputType = "email", onChange },
) => {
  return (
    // To be inaccessible to screen readers, use aria-hidden
    <Fieldset aria-hidden="true" tabIndex={-1}>
      <label aria-hidden="true" htmlFor={name} tabIndex={-1}>
        {label}
      </label>
      <input
        aria-hidden="true"
        type={inputType}
        name={name}
        id={name}
        placeholder={label}
        // Chrome does not support autocomplete="off" but it does support autocomplete="one-time-code"
        autoComplete="one-time-code"
        // Ensure the field is not focusable via tabbing
        tabIndex={-1}
        onChange={onChange}
      />
    </Fieldset>
  );
};

// Using display: none or visibility: hidden can be ineffective against spam bots, so we use a less common approach
const Fieldset = styled.fieldset`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
  overflow: hidden;
`;
